import React, { useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { styled, useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  LIST_EMPLOYEE,
  LIST_LEAD,
  LIST_MANAGER,
  IMAGES_LIST,
} from "./Constants";
import logoWhite from "../Images/logo-white.png";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import {
  faMugHot,
  faChampagneGlasses,
  faEnvelope,
  faGaugeHigh,
  faBriefcase,
  faImages,
  faUsersGear,
  faBusinessTime,
  faUsers,
  faInbox,
  faUserShield,
  faUserTie,
  faUser,
  faRightFromBracket,
  faQuoteLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope as faEnvelopRegular } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  faGaugeHigh,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  overflowY: "auto",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Header({ handleClick, openMenu, profileImage, name }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  const handleDrawerClose = () => {
    setOpen(!open);
  };
  const handleChange = () => {
    navigate("/dashboard", { replace: true });
  };
  const role = localStorage.getItem("role");

  const handleOnClick = (index) => {
    if (index === 0) navigate("/dashboard", { replace: true });
    else if (index === 1) navigate("/holidays", { replace: true });
    else if (index === 2) navigate("/applyforleave", { replace: true });
    else if (index === 3) navigate("/appliedleaves", { replace: true });
    else if (index === 4) navigate("/applycompo", { replace: true });
    else if (index === 5) navigate("/listCompo", { replace: true });
    else if (index === 6) navigate("/album", { replace: true });
    else if (index === 7) navigate("/leaveModerators", { replace: true });
    else if (index === 8) navigate("/compoModerators", { replace: true });
  };

  const handleOnManagerClick = (index) => {
    if (index === 0) navigate("/dashboard", { replace: true });
    else if (index === 1) navigate("/role/employee/users", { replace: true });
    else if (index === 2) navigate("/role/lead/users", { replace: true });
    else if (index === 3) navigate("/role/manager/users", { replace: true });
    else if (index === 4) navigate("/role/alumini/users", { replace: true });
    else if (index === 5) navigate("/leaveTypes", { replace: true });
    else if (index === 6) navigate("/quotes", { replace: true });
    else if (index === 7) navigate("/holidays", { replace: true });
    else if (index === 8) navigate("/applyforleave", { replace: true });
    else if (index === 9) navigate("/appliedleaves", { replace: true });
    else if (index === 10) navigate("/reset", { replace: true });
    else if (index === 11) navigate("/applycompo", { replace: true });
    else if (index === 12) navigate("/listCompo", { replace: true });
    else if (index === 13) navigate("/album", { replace: true });
    else if (index === 14) navigate("/leaveModerators", { replace: true });
    else if (index === 15) navigate("/compoModerators", { replace: true });
  };

  return (
    <>
      <AppBar position="fixed" color="inherit" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerClose}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Tooltip
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={handleClick}
                aria-controls={openMenu ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
              >
                <Avatar
                  src={`${IMAGES_LIST}${profileImage}`}
                  alt="profileimage"
                />
              </IconButton>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  marginLeft: "6px",
                }}
              >
                {name}
              </span>
            </div>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: "#000737",
          },
        }}
      >
        <DrawerHeader>
          <Box
            sx={{
              padding: "15px",
              mr: "40px",
            }}
          >
            <img
              src={logoWhite}
              alt="Xminds"
              height="40px"
              onClick={handleChange}
            />
          </Box>
        </DrawerHeader>
        <Divider />

        {role === "employee" && (
          <List>
            {LIST_EMPLOYEE.map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                onClick={() => handleOnClick(index)}
                sx={{
                  display: "block",
                  color: "rgb(244 245 253) !important",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "rgb(244 245 253) !important",
                    }}
                  >
                    {index === 0 && (
                      <FontAwesomeIcon icon={faGaugeHigh} size="lg" />
                    )}
                    {index === 1 && (
                      <FontAwesomeIcon icon={faChampagneGlasses} size="lg" />
                    )}
                    {index === 2 && (
                      <FontAwesomeIcon icon={faMugHot} size="lg" />
                    )}
                    {index === 3 && (
                      <FontAwesomeIcon icon={faEnvelope} size="lg" />
                    )}
                    {index === 4 && (
                      <FontAwesomeIcon icon={faBriefcase} size="lg" />
                    )}
                    {index === 5 && (
                      <FontAwesomeIcon icon={faEnvelopRegular} size="lg" />
                    )}
                    {index === 6 && (
                      <FontAwesomeIcon icon={faImages} size="lg" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}

        {role === "lead" && (
          <List>
            {LIST_LEAD.map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                onClick={() => handleOnClick(index)}
                sx={{
                  display: "block",
                  color: "rgb(244 245 253) !important",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "rgb(244 245 253) !important",
                    }}
                  >
                    {index === 0 && (
                      <FontAwesomeIcon icon={faGaugeHigh} size="lg" />
                    )}
                    {index === 1 && (
                      <FontAwesomeIcon icon={faChampagneGlasses} size="lg" />
                    )}
                    {index === 2 && (
                      <FontAwesomeIcon icon={faMugHot} size="lg" />
                    )}
                    {index === 3 && (
                      <FontAwesomeIcon icon={faEnvelope} size="lg" />
                    )}
                    {index === 4 && (
                      <FontAwesomeIcon icon={faBriefcase} size="lg" />
                    )}
                    {index === 5 && (
                      <FontAwesomeIcon icon={faEnvelopRegular} size="lg" />
                    )}
                    {index === 6 && (
                      <FontAwesomeIcon icon={faImages} size="lg" />
                    )}
                    {index === 7 && (
                      <FontAwesomeIcon icon={faUsersGear} size="lg" />
                    )}
                    {index === 8 && <FontAwesomeIcon icon={faBusinessTime} />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}

        {role === "manager" && (
          <List>
            {LIST_MANAGER.map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                sx={{
                  display: "block",
                  color: "rgb(244 245 253) !important",
                }}
                onClick={() => handleOnManagerClick(index)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "rgb(244 245 253) !important",
                    }}
                  >
                    {index === 0 && (
                      <FontAwesomeIcon icon={faGaugeHigh} size="sm" />
                    )}
                    {index === 1 && (
                      <FontAwesomeIcon icon={faUsers} size="sm" />
                    )}
                    {index === 2 && (
                      <FontAwesomeIcon icon={faUserShield} size="sm" />
                    )}
                    {index === 3 && (
                      <FontAwesomeIcon icon={faUserTie} size="sm" />
                    )}
                    {index === 4 && <FontAwesomeIcon icon={faUser} size="sm" />}
                    {index === 5 && (
                      <FontAwesomeIcon icon={faRightFromBracket} size="sm" />
                    )}
                    {index === 6 && (
                      <FontAwesomeIcon icon={faQuoteLeft} size="sm" />
                    )}
                    {index === 7 && (
                      <FontAwesomeIcon icon={faChampagneGlasses} size="sm" />
                    )}
                    {index === 8 && (
                      <FontAwesomeIcon icon={faMugHot} size="sm" />
                    )}
                    {index === 9 && (
                      <FontAwesomeIcon icon={faEnvelope} size="sm" />
                    )}
                    {index === 10 && (
                      <FontAwesomeIcon icon={faBriefcase} size="sm" />
                    )}
                    {index === 11 && (
                      <FontAwesomeIcon icon={faEnvelopRegular} size="sm" />
                    )}
                    {index === 12 && (
                      <FontAwesomeIcon icon={faInbox} size="sm" />
                    )}
                    {index === 13 && (
                      <FontAwesomeIcon icon={faImages} size="sm" />
                    )}
                    {index === 14 && (
                      <FontAwesomeIcon icon={faUsersGear} size="sm" />
                    )}
                    {index === 15 && (
                      <FontAwesomeIcon icon={faBusinessTime} size="sm" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        {role === "admin" && (
          <List>
            {LIST_MANAGER.map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                sx={{
                  display: "block",
                  color: "rgb(244 245 253) !important",
                }}
                onClick={() => handleOnManagerClick(index)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "rgb(244 245 253) !important",
                    }}
                  >
                    {index === 0 && (
                      <FontAwesomeIcon icon={faGaugeHigh} size="sm" />
                    )}
                    {index === 1 && (
                      <FontAwesomeIcon icon={faUsers} size="sm" />
                    )}
                    {index === 2 && (
                      <FontAwesomeIcon icon={faUserShield} size="sm" />
                    )}
                    {index === 3 && (
                      <FontAwesomeIcon icon={faUserTie} size="sm" />
                    )}
                    {index === 4 && <FontAwesomeIcon icon={faUser} size="sm" />}
                    {index === 5 && (
                      <FontAwesomeIcon icon={faRightFromBracket} size="sm" />
                    )}
                    {index === 6 && (
                      <FontAwesomeIcon icon={faQuoteLeft} size="sm" />
                    )}
                    {index === 7 && (
                      <FontAwesomeIcon icon={faChampagneGlasses} size="sm" />
                    )}
                    {index === 8 && (
                      <FontAwesomeIcon icon={faMugHot} size="sm" />
                    )}
                    {index === 9 && (
                      <FontAwesomeIcon icon={faEnvelope} size="sm" />
                    )}
                    {index === 10 && (
                      <FontAwesomeIcon icon={faBriefcase} size="sm" />
                    )}
                    {index === 11 && (
                      <FontAwesomeIcon icon={faEnvelopRegular} size="sm" />
                    )}
                    {index === 12 && (
                      <FontAwesomeIcon icon={faInbox} size="sm" />
                    )}
                    {index === 13 && (
                      <FontAwesomeIcon icon={faImages} size="sm" />
                    )}
                    {index === 14 && (
                      <FontAwesomeIcon icon={faUsersGear} size="sm" />
                    )}
                    {index === 15 && (
                      <FontAwesomeIcon icon={faBusinessTime} size="sm" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Drawer>
    </>
  );
}

export default Header;
