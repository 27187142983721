import React, { useEffect, useState,useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { Avatar, Card, Grid, IconButton } from "@mui/material";
import { IMAGES_LIST } from "./Constants";
import { CameraAlt } from "@mui/icons-material";
import moment from "moment";
import Service from "./services";
import CancelIcon from "@mui/icons-material/Cancel";
import ProfileTab from "./Tab/ProfileTab";

function Profile() {
  const profileImage = localStorage.getItem("profileimage");
  const [designation, setDesignation] = useState("");
  const [employee, setEmployee] = useState(false);
  const [id, setId] = useState();
  const fileInputRef = useRef(null);
  const Edit = "profile";
  const [userDetails, setUserDetails] = useState([]);
  const Joined_On = localStorage.getItem("joinedOn");
  const name = localStorage.getItem("name");
  const isProfile = true;
  const empStatus = employee ? "Alumini" : "Current Employee" ;

  const handleCancel = () => {
    fileInputRef.current.value = "";
    localStorage.setItem("profileimage", " ");
    getUserDetails();
  };
  const handleFileChange = (e) => {
    const image = e.target.files[0];
    handleUpload(image);
  };
  const getUserDetails = async () => {
    try {
      const res = await Service.getUserdetails();
      const birthday = res.data.dob;
      const JoinedOn = res.data.joined_on;
      const convertedBirthday = moment(birthday).format("YYYY-MM-DD");
      const convertedJoinedOn = moment(JoinedOn).format("YYYY-MM-DD");
      setId(res.data.id);
      setEmployee(res.data.old_employee);
      setDesignation(res.data.designation);
      setUserDetails({
        firstName: res.data.first_name,
        middleName: res.data.middle_name,
        lastName: res.data.last_name,
        gender: res.data.gender,
        bloodGroup: res.data.blood_group,
        employeeId: res.data.employee_id,
        designation: res.data.designation,
        email: res.data.name,
        mobileNo: res.data.mobile_no,
        birthday: convertedBirthday,
        joinedOnDate: convertedJoinedOn,
      });
    } catch (error) {
      // setError(error.response.data.message);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);
  const initialValues = {
    password: "",
    password_confirmation: "",
  };
  const handleUpload = async (image) => {
    try {
      const res = await Service.UploadImage(image);
      localStorage.setItem("profileimage", res.data.file);
      await getUserDetails();
    } catch (error) {}
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize="29px"
          fontWeight={700}
          fontFamily="'Inter', sans-serif"
        >
          Profile
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "16px",
            fontFamily: "'Inter', sans-serif",
          }}
        >
          <Link href="https:/xminds.com" underline="hover">
            Home
          </Link>
          &nbsp;
          <Typography> / User Profile</Typography>
        </Box>
      </Box>
      <Card
        sx={{
          maxWidth: "2000px",
          height: "201px",
          mt: 3,
          borderRadius: "10px",
        }}
      >
        <Grid container sx={{ mt: 3 }}>
          <Grid item md={2}>
            <Grid container>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={IMAGES_LIST + profileImage}
                  alt=""
                  sx={{ width: 120, height: 120 }}
                />
              </Grid>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <IconButton component="label" sx={{ color: "#06c4a5" }}>
                  <CancelIcon onClick={handleCancel} sx={{ color: "red" }} />
                </IconButton>

                <IconButton
                  component="label"
                  htmlFor="fileInput"
                  sx={{ color: "#06c4a5" }}
                >
                  <CameraAlt />
                  <input
                    id="fileInput"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={10}>
            <Typography
              sx={{
                fontSize: "18px",
                fontFamily: "'Inter', sans-serif",
                fontWeight: "bold",
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "'Inter', sans-serif",
                fontWeight: "bold",
                color: "#6c757d!important",
              }}
            >
              {designation}
            </Typography>
            <Box sx={{ mt: 2, color: "#6c757d!important" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  fontFamily: "'Inter', sans-serif",
                }}
              >
                Joined on: <span>{moment(Joined_On).format("DD.MM.YYYY")}</span>
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  fontFamily: "'Inter', sans-serif",
                }}
              >
                Employee Status: <span>{empStatus}</span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Card sx={{ mt: 3, borderRadius: "10px" }}>
        <ProfileTab
          isView={isProfile}
          setUserDetails={setUserDetails}
          Edit={Edit}
          viewUserDetails={userDetails}
          id={id}
          initialValues={initialValues}
        />
      </Card>
      <Footer />
    </Box>
  );
}

export default Profile;
