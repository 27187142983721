import {
  Box,
  Button,
  Grid,
  Pagination,
  Typography,
  TextField,
  MenuItem,
  IconButton,
  CardContent,
  Card,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IMAGES_LIST, entries } from "./Constants";
import Footer from "./Footer";
import ImageCarousel from "./ImageCarousel";
import { useNavigate } from "react-router-dom";
import Service from "../Components/services";
import SnackBar from "./Common/SnackBar";

function ViewImageGallery() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [msg, setMsg] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const role = localStorage.getItem("role");

  useEffect(() => {
    albumImageList();
  }, []);
  const style = {
    sucesscard: {
      height: "50px",
      backgroundColor: "#28a745",
      color: "#fff",
    },
    successmsg: {
      paddingBottom: "10px",
      mt: -1.5,
    },
    errorcard: {
      height: "50px",
      backgroundColor: "#dc3545",
      color: "#fff",
    },
    errormsg: {
      paddingBottom: "10px",
      mt: -1.5,
    },
  };
  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClick = async (id, albumId) => {
    const imageData = {
      albumid: albumId,
      imageid: id,
    };
    try {
      const { data } = await Service.deleteAlbumImage(imageData);
      setSnackOpen(true);
      setMsg(data.message);
      albumImageList();
    } catch (error) {
      setMsg(error.data.message);
    }
  };

  const handleFilter = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };
  const handleChange = (e, p) => {
    setPage(p);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const albumData = {
    album: state.albumid,
    page: page,
    limit: perPage,
  };
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const albumImageList = async () => {
    try {
      const { data } = await Service.albumImageList(albumData);
      setData(data.data);
      setPageCount(Math.ceil(data.total_count / perPage));
    } catch (error) {
      // setError(error.response.data);
    }
  };
  const handleUpload = () => {
    navigate("/album/upload", {
      state: {
        id: state.albumid,
      },
      replace: true,
    });
  };
  const styles = {
    image: {
      marginLeft: "auto",
      marginRight: "auto",
      height: "100%",
      maxHeight: "400px",
    },
    buttonStyles: {
      bottom: "90%",
      left: "90%",
      color: "#FF0000",
    },
    typo1: {
      fontSize: "1.8rem",
      fontWeight: 700,
      color: "#000736 !important",
      paddingBottom: "15px",
    },
    box1: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    button: {
      fontSize: "12px",
    },
    paginationbox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "20px",
    },
  };
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
      <Box sx={styles.box1}>
        <Typography style={styles.typo1}>Image Gallery</Typography>
        {role === "admin" || role === "manager" ? (
          <Button variant="contained" sx={styles.button} onClick={handleUpload}>
            UPLOAD IMAGE
          </Button>
        ) : (
          ""
        )}
      </Box>
      <Box>
        <Typography>
          Show{" "}
          <span>
            <TextField
              id="outlined-select-currency"
              select
              defaultValue="10"
              value={perPage}
              onChange={handleFilter}
              inputProps={{
                sx: {
                  paddingRight: "30px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                  fontSize: "75%",
                },
              }}
            >
              {entries.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>{" "}
          entries
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {data
          ? data.map((obj, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                lg={3}
                key={index}
                sx={{ marginTop: 4, position: "relative" }}
              >
                <img
                  sx={styles.image}
                  src={IMAGES_LIST + obj.image}
                  alt={obj.description}
                  width="100%"
                  onClick={() => handleOpen(obj.image)}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "transparent",
                    color: "#FF0000",
                  }}
                  variant="outlined"
                  onClick={() => handleClick(obj.id, obj.album_id)}
                >
                  <CancelIcon />
                </IconButton>
              </Grid>
            ))
          : null}

        <ImageCarousel
          open={open}
          onClose={handleClose}
          selectedImage={selectedImage}
          data={data}
        />
      </Grid>
      <Box sx={styles.paginationbox}>
        <Pagination
          count={pageCount}
          color="primary"
          shape="rounded"
          page={page}
          onChange={handleChange}
        />
      </Box>
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        messageName={msg}
      />
      <Footer />
    </Box>
  );
}

export default ViewImageGallery;
