import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  Button,
  InputAdornment,
  Grid,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { object, string } from "yup";
import Service from "../../services";
import { useState, useEffect } from "react";
import SnackBar from "../../Common/SnackBar";

export default function ForgotPassword() {
  const navigate = useNavigate("");
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [snackOpen, setSnackOpen] = useState(false);
  const [url, setUrl] = useState("");
  const styles = {
    typo: {
      fontSize: "18px",
      fontWeight: 600,
    },
    login: {
      display: "flex",
      justifyContent: "center",
      mt: 2,
    },
    btn: {
      backgroundColor: " #000737",
      width: "320px",
      mt: 3,
    },
    sucesscard: {
      height: "50px",
      backgroundColor: "#28a745",
      color: "#fff",
    },
    successmsg: {
      paddingBottom: "10px",
    },
    errorcard: {
      height: "50px",
      backgroundColor: "#dc3545",
      color: "#fff",
    },
    errormsg: {
      paddingBottom: "10px",
    },
  };
  const initialValues = {
    email: "",
  };
  const forgotPasswordSchema = object({
    email: string().email().required("Email is required"),
  });
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: forgotPasswordSchema,
      onSubmit: async (values, action) => {
        values.url = url;
        try {
          const res = await Service.forgotPassword(values);
          setSuccess(res.data.message);
        } catch (error) {
          setError(error.response.data.message);
        }
        action.resetForm();
      },
    });

  useEffect(() => {
    const url = new URL(window.location.href);
    const baseUrl = url.origin;
    if (baseUrl) {
      setUrl(baseUrl);
    }else{

    }
  }, []);
  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        justifyContent: "center",
        mt: 24,
      }}
    >
      <Card sx={{ maxWidth: "360px", width: "100%" }}>
        <CardContent>
          <Box>
            <Box
              sx={{
                margin: 0,
                padding: "20px 20px",
                textAlign: "center",

                color: "black",
              }}
            >
              <Typography sx={styles.typo}>
                You forgot your password? Here you can easily retrieve a new
                password.
              </Typography>
            </Box>
            <Grid item>
              <TextField
                type="email"
                placeholder="Email"
                name="email"
                size="small"
                InputProps={{
                  style: {
                    width: "320px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                value={values.email}
                error={errors.email || error}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <Typography fontSize="small" color="#dc3545">
                  {errors.email}
                </Typography>
              ) : null}
              {/* {error && (
                <Typography fontSize="small" color="#dc3545">
                  {error}
                </Typography>
              )} */}
            </Grid>
            <Button variant="contained" sx={styles.btn} onClick={handleSubmit}>
              Send Password Resent Link
            </Button>
          </Box>
          <Box sx={styles.login}>
            <Button size="small" onClick={() => navigate(-1)}>
              Login
            </Button>
          </Box>
        </CardContent>
      </Card>
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        messageName={success}
        errorMsg={error}
      />
    </Box>
  );
}
