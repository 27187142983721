import { Box, Card, CardContent, Typography } from "@mui/material";
import Service from "../../services";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import LeaveTypeAddEdit from "../../Common/LeaveTypeAddEdit";
import SnackBar from "../../Common/SnackBar";

const styles = {
  typo1: {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  errorcard: {
    height: "50px",
    backgroundColor: "#dc3545",
    color: "#fff",
  },
  errormsg: {
    paddingBottom: "10px",
  },
};
function AddLeaveType() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);

  const initialValues = {
    description: "",
    is_active: 0,
    is_compo: 0,
    is_core: 0,
    number_of_leaves: "",
    title: "",
  };
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const addLeaveSchema = yup.object({
    title: yup.string().required("The title field is required").matches(/^[a-zA-Z\s]*$/,"Title must contain only letters"),
    description: yup.string().required("The description field is required"),
  });
  const addLeaveType = async (value) => {
    try {
      const res = await Service.postLeaveType(value);
      navigate("/leaveTypes", {
        state: {
          message: res.data.message,
          open:true,
        },
      });
    } catch (error) {
      setSnackOpen(true);
      setError(error.response.data.message);
    }
  };

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 1.5, mt: 8, width: "calc(100vw - 260px)" }}
    >
      <Typography style={styles.typo1}>Create LeaveType</Typography>
      <Card
        sx={{
          maxHeight: "auto",
          mt: 1,
          borderRadius: "10px",
        }}
      >
        <CardContent
          sx={{
            color: "rgba(39,44,51,.7)!important",
            fontSize: "14px",
          }}
        >
          <LeaveTypeAddEdit
            leaveTypeSchema={addLeaveSchema}
            initialValues={initialValues}
            fetchData={addLeaveType}
          />
        </CardContent>
      </Card>
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        errorMsg={error}
      />
    </Box>
  );
}

export default AddLeaveType;
