import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import Service from "../../services";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import SnackBar from "../../Common/SnackBar";

const styles = {
  typo1: {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  checkboxlabel: {
    "& .MuiTypography-root": {
      fontWeight: 600,
      color: "rgba(39,44,51,.7)!important",
    },
  },
  label: {
    fontSize: "14px",
    color: "rgba(39,44,51,.7)!important",
  },
  uploadBox: {
    backgroundColor: "#f2f8ff",
    minWidth: 150,
    height: 65,
    borderRadius: 2,
    border: "1px dashed #262d5c",
  },
  boxTypo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cancelButton: {
    backgroundColor: "#f8f9fa",
    color: "#444",
  },
};
function AddQuotes() {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [snackOpen, setSnackOpen] = useState(false);

  const initialValues = {
    description: "",
    status: "",
    image: "",
  };
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const addQuotesSchema = object({
    description: string().required("This field is required"),
  });
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: addQuotesSchema,
      onSubmit: async (values) => {
        try {
          const res = await Service.postQuote(values);
          navigate("/quotes", {
            state: {
              message: res.data.message,
              open: true,
            },
          });
        } catch (error) {
          setSnackOpen(true);
          setError(error.response.data.error);
        }
      },
    });
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 1.5, mt: 8, width: "calc(100vw - 260px)" }}
    >
      <Typography style={styles.typo1}>Create Quote</Typography>
      <Card
        sx={{
          maxHeight: "auto",
          mt: 1,
          borderRadius: "10px",
        }}
      >
        <CardContent
          sx={{
            color: "rgba(39,44,51,.7)!important",
            fontSize: "14px",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Typography sx={styles.label}>Description:</Typography>
            <TextField
              name="description"
              fullWidth
              size="small"
              multiline
              minRows={10}
              value={values.description}
              helperText={touched.description && errors.description}
              error={errors.description}
              onChange={handleChange}
            />

            <FormControlLabel
              name="status"
              control={
                <Checkbox
                  sx={{ transform: "scale(0.8)" }}
                  checked={values.status}
                  onChange={(event) => {
                    const isChecked = event.target.checked;
                    setFieldValue("status", isChecked ? 1 : 0);
                  }}
                />
              }
              label={<span sx={{ fontSize: "14px" }}>Status</span>}
              sx={styles.checkboxlabel}
            />
            <Box sx={{ mt: 2 }}>
              <Typography sx={styles.label}>Upload Image:</Typography>
              <label htmlFor="fileInput">
                <Box sx={styles.uploadBox}>
                  {values.image === "" ? (
                    <Typography width="100%" height="100%" sx={styles.boxTypo}>
                      UPLOAD A FILE
                    </Typography>
                  ) : (
                    <Typography width="100%" height="100%" sx={styles.boxTypo}>
                      CHANGE IMAGE
                    </Typography>
                  )}
                </Box>
              </label>
              <input
                id="fileInput"
                type="file"
                name="image"
                style={{ display: "none" }}
                onChange={(event) => {
                  const file = event.target.files[0];
                  setFieldValue("image", file);
                }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Button variant="contained" onClick={handleSubmit}>
                Save
              </Button>
              &nbsp;
              <Button variant="contained" style={styles.cancelButton}>
                Cancel
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        errorMsg={error}
      />
    </Box>
  );
}

export default AddQuotes;
