import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AlertDialog from "../Common/AlertDialog";
import Service from "../services";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ClearIcon from "@mui/icons-material/Clear";
import {
  entries,
  listCompoModeratorsTableHeader,
  COMPOMODERATORSHEAD,
  COMPOMODERATORS,
  DELETECOMPOMODERATORS,
} from "../Constants";
import Footer from "../Footer";
import { API_LINK, BACKEND_API_LINK } from "../Constants";
import SnackBar from "../Common/SnackBar";

function CompoModerators() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [date, setDate] = useState("");
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [query, setQuery] = useState("");
  const [order, setOrder] = useState("asc");
  const [field, setField] = useState("email");
  const [perPage, setPerPage] = useState(10);
  const [snackOpen, setSnackOpen] = useState(state?.open);
  const [msg, setMsg] = useState("");
  window.history.replaceState({}, document.title);

  const style = {
    sucesscard: {
      height: "50px",
      backgroundColor: "#28a745",
      color: "#fff",
    },
    successmsg: {
      paddingBottom: "10px",
      mt: 2,
    },
    errorcard: {
      height: "50px",
      backgroundColor: "#dc3545",
      color: "#fff",
    },
    errormsg: {
      paddingBottom: "10px",
      mt: -1.5,
    },
  };
  const handleChange = (e, p) => {
    setPage(p);
  };
  const handleClearDate = () => {};
  const handleView = (val) => {
    const CompoModeratorHeader = [
      { head: COMPOMODERATORSHEAD.Compo_Project, value: val.compo_project },
      {
        head: COMPOMODERATORSHEAD.Compo_Date,
        value: val.compo_date,
      },
      { head: COMPOMODERATORSHEAD.User_Name, value: val.name },
      { head: COMPOMODERATORSHEAD.User_Email, value: val.email },

      {
        head: COMPOMODERATORSHEAD.Remarks,
        value: val.remarks,
      },

      {
        head: COMPOMODERATORSHEAD.Status,
        value: val.status,
      },
    ];
    navigate(`/viewcompomoderators/${val.id}`, {
      state: {
        datalist: CompoModeratorHeader,
        header: COMPOMODERATORS,
      },
    });
  };
  const handleEdit = (val) => {
    navigate(`/compoModerators/${val.moderator_id}/edit`, {
      state: {
        value: val,
      },
    });
  };
  const handleDeleteClick = (moderator_id) => {
    setId(moderator_id);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      const res = await Service.deleteCompoModerators(id);
      await moderatorsList();
      setMsg(res.data.error);
      setSnackOpen(true);
      setOpen(false);
    } catch (error) {
      // setError(error.response.data.message);
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const moderatorsList = async () => {
    
    try {
      const dates = date === "Invalid date" ? "" : date
      const res = await Service.CompoModerationLeadList(
        query,
        dates,
        field,
        order
      );
      setData(res.data.data);
      setPageCount(Math.ceil(res.data.count / perPage));
    } catch (error) {
      // Handle errors here
    }
  };

  useEffect(() => {
    moderatorsList();
  }, [query, date, field, order, perPage]);

  if (state) {
    setTimeout(() => {
      setSnackOpen(false);
    }, 2000);
  }
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const handleDateChange = (e) => {
    setDate(moment(e).format("YYYY-MM-DD"));
    if(e === null) {
      moderatorsList();
    }else{

    }
  };

  const handleSort = (column) => {
    const isAsc = field === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setField(column);
  };

  const handleFilter = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };
  const handleSearch = (e) => {
    setQuery(e.target.value);
    setPage(1);
  };

  const styles = {
    typo1: {
      fontSize: "1.8rem",
      fontWeight: 700,
    },
    action1: {
      fontSize: "10px",
      p: "6px 16px 5px",
      borderColor: "#5b67c7",
      color: "#5b67c7",
      ":hover": {
        color: "#fff !important",
        backgroundColor: "#5b67c7",
        borderColor: "#5b67c7",
      },
    },
    action2: {
      fontSize: "10px",
      p: "6px 16px 5px",
      borderColor: "#38cab3",
      color: "#38cab3",
      ":hover": {
        color: "#fff !important",
        backgroundColor: "#38cab3",
        borderColor: "#38cab3",
      },
    },
    action3: {
      fontSize: "10px",
      p: "6px 16px 5px",
      borderColor: "#dc3545",
      color: "#dc3545",
      ":hover": {
        color: "#fff !important",
        backgroundColor: "#dc3545",
        borderColor: "#dc3545",
      },
    },
    sucesscard: {
      height: "50px",
      backgroundColor: "#28a745",
      color: "#fff",
    },
    successmsg: {
      paddingBottom: "10px",
    },
    ".MuiTableCell-root ": {
      color: "#495057",
      fontFamily: "Inter, sans-serif",
    },
  };
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 2, mt: 8, width: "calc(100vw - 260px)" }}
    >
      <Typography style={styles.typo1}>Compo Moderators</Typography>
      <Box>
        <Card
          sx={{
            maxHeight: "auto",
            mt: 1,
            borderRadius: "10px",
            border: "0.1px solid #dadce0",
          }}
        >
          <CardContent
            sx={{
              color: "rgba(39,44,51,.7)!important",
              fontSize: "14px",
            }}
          >
            <Grid
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid item xs={4} md={4}>
                <Typography>
                  Show{" "}
                  <span>
                    <TextField
                      id="outlined-select-currency"
                      select
                      defaultValue="10"
                      value={perPage}
                      onChange={handleFilter} // (e) => setPerPage(e.target.value)
                      inputProps={{
                        sx: {
                          paddingRight: "30px",
                          paddingTop: "3px",
                          paddingBottom: "3px",
                          fontSize: "75%",
                        },
                      }}
                    >
                      {entries.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </span>{" "}
                  entries
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDiretion: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography>Search:</Typography>&nbsp;
                  <TextField
                    size="small"
                    onChange={handleSearch}
                    inputProps={{
                      sx: {
                        height: "1em",
                      },
                    }}
                  ></TextField>
                </Box>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      views={['year', 'month', 'day']}
                      name="date"
                      format="YYYY-MM-DD"
                      inputProps={{
                        sx: {
                          width: "100%",
                          maxWidth: "200px",
                        },
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },

                        field: { clearable: true },
                      }}
                      onChange={handleDateChange}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
            </Grid>

            <TableContainer
              component={Box}
              sx={{ mt: 1.5, borderRadius: "10px" }}
            >
              <Table sx={{ minWidth: 700 }} aria-label="simple table">
                <TableHead sx={{ background: "#c2d0ff" }}>
                  <TableRow>
                    {listCompoModeratorsTableHeader.map((value) => (
                      <TableCell align="left" key={value.id}>
                        <TableSortLabel
                          active={field === value.label}
                          direction={field === value.label ? order : "asc"}
                          onClick={() => handleSort(value.label)}
                        >
                          {value.label.toUpperCase()}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data ? (
                    data.map((value) => (
                      <TableRow
                        key={value.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={styles[".MuiTableCell-root "]}
                          align="left"
                        >
                          {value.email}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={styles[".MuiTableCell-root "]}
                        >
                          {value.user_name}{" "}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={styles[".MuiTableCell-root "]}
                        >
                          {value.compo_project}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={styles[".MuiTableCell-root "]}
                        >
                          {value.compo_date}{" "}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={styles[".MuiTableCell-root "]}
                        >
                          {value.status}{" "}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={styles[".MuiTableCell-root "]}
                        >
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Button
                              variant="outlined"
                              sx={styles.action1}
                              onClick={() => handleView(value)}
                            >
                              View
                            </Button>
                            &nbsp;
                            {value.status === "Pending" ? (
                              <Button
                                variant="outlined"
                                sx={styles.action2}
                                onClick={() => handleEdit(value)}
                              >
                                Edit
                              </Button>
                            ) : (
                              ""
                            )}
                            &nbsp;
                            <Button
                              variant="outlined"
                              sx={styles.action3}
                              onClick={() =>
                                handleDeleteClick(value.moderator_id)
                              }
                            >
                              Delete
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={12}
                        style={styles.tableCellNoData}
                      >
                        No Matching Records Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center-",
              }}
            >
              <Pagination
                count={pageCount}
                color="primary"
                shape="rounded"
                page={page}
                onChange={handleChange}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Footer />
      <AlertDialog
        open={open}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
        title={DELETECOMPOMODERATORS}
      />
      <SnackBar
        openSnackerBar={snackOpen}
        errorMsg={state?.success ? "" : state?.message}
        handleCloseSnackBar={handleCloseSnackbar}
        messageName={msg ? msg : state?.message}
      />
    </Box>
  );
}

export default CompoModerators;
