import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import Service from "./services";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  entries,
  listCompoTableHeader,
} from "./Constants";
import Footer from "./Footer";
import SnackBar from "./Common/SnackBar";

function AppliedCompo() {
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [query, setQuery] = useState("");
  const [order, setOrder] = useState("asc");
  const [field, setField] = useState("compo_date");
  const [perPage, setPerPage] = useState(10);
  const [snackOpen, setSnackOpen] = useState(state?.open);

  window.history.replaceState({}, document.title);

  const handleChange = (e, p) => {
    setPage(p);
  };
  useEffect(() => {
    getAppliedCompo();
  }, [page, query, order, perPage]);
  const getAppliedCompo = async () => {
    try {
      const res = await Service.getAppliedCompoList(page, query, order, perPage, field);
      setData(res.data.data);
      setPageCount(Math.ceil(res.data.total_leave_count / perPage));
    } catch (error) {
    }
  };

  const handleSort = (column) => {
    const isAsc = field === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setField(column);
  };

  const handleFilter = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };
  const handleSearch = (e) => {
    setQuery(e.target.value);
    setPage(1);
  };
  if (state) {
    setTimeout(() => {
      setSnackOpen(false)
    }, 2000);
  }
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const styles = {
    typo1: {
      fontSize: "1.8rem",
      fontWeight: 700,
    },
    sucesscard: {
      height: "50px",
      backgroundColor: "#28a745",
      color: "#fff",
    },
    successmsg: {
      paddingBottom: "10px",
    },
    ".MuiTableCell-root ": {
      color: "#495057",
      fontFamily: "Inter, sans-serif",
    },
  };
  return (
    <Box component="main"       sx={{ flexGrow: 1, p: 2, mt: 8, width: "calc(100vw - 260px)" }}
    >
      <Typography style={styles.typo1}>Applied Compo's</Typography>
      <Box>
        <Card
          sx={{
            maxHeight: "auto",
            mt: 1,
            borderRadius: "10px",
            border: "0.1px solid #dadce0",
          }}
        >
          <CardContent
            sx={{
              color: "rgba(39,44,51,.7)!important",
              fontSize: "14px",
            }}
          >
            <Grid
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid item md={6}>
                <Typography>
                  Show{" "}
                  <span>
                    <TextField
                      id="outlined-select-currency"
                      select
                      defaultValue="10"
                      value={perPage}
                      onChange={handleFilter} 
                      inputProps={{
                        sx: {
                          paddingRight: "30px",
                          paddingTop: "3px",
                          paddingBottom: "3px",
                          fontSize: "75%",
                        },
                      }}
                    >
                      {entries.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </span>
                  {" "}entries
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDiretion: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography>Search:</Typography>&nbsp;
                  <TextField
                    size="small"
                    onChange={handleSearch}
                    inputProps={{
                      sx: {
                        height: "1em",
                      },
                    }}
                  ></TextField>
                </Box>
              </Grid>
            </Grid>

            <TableContainer
              component={Box}
              sx={{ mt: 1.5, borderRadius: "10px" }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{ background: "#c2d0ff" }}>
                  <TableRow>
                    {listCompoTableHeader.map((value) => (
                      <TableCell align="left" key={value.id}>
                        <TableSortLabel
                          active={field === value.name}
                          direction={field === value.name ? order : "asc"}
                          onClick={() => handleSort(value.name)}
                        >
                          {value.label.toUpperCase()}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data ? (
                    data.map((value) => (
                      <TableRow
                        key={value.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={styles[".MuiTableCell-root "]}
                          align="left"
                        >
                          {moment(value.compo_date).format("MMM DD, YYYY")}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={styles[".MuiTableCell-root "]}
                        >
                          {value.compo_project}{" "}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={styles[".MuiTableCell-root "]}
                        >
                          {moment(value.applied_date).format("MMM DD, YYYY")}{" "}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={styles[".MuiTableCell-root "]}
                        >
                          {value.status}{" "}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={12}
                        style={styles.tableCellNoData}
                      >
                        No Matching Records Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center-",
              }}
            >
              <Pagination
                count={pageCount}
                color="primary"
                shape="rounded"
                page={page}
                onChange={handleChange}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        messageName={state?.message}
      />
      <Footer />
    </Box>
  );
}

export default AppliedCompo;
