import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Avatar } from "@mui/material";
import { fontWeight } from "@mui/system";
import {
  IMAGES_LIST
} from "./Constants";

function QuotesDialogBox({ openDialog, handleCloseDialog,quote }) {
  const styles = {
    Dialogtitle: {
      color: " #00395a",
      fontWeight: 700,
      mt: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    button: {
      borderRadius: "50px",
      width: "40%",
    },
    actions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      mb: 4,
    },
    content: {
        fullWidth:true,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      mt:1.5,
      fontFamily:"'Inter', sans-serif"
    },
    contenttext:{
        mt:"16px",
        fontSize:"16px",
        color: "#00395a",
        width:"85%",
        pb:"15px",
        textAlign : "center", 
    }
  };
  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title" sx={styles.Dialogtitle}>
          {"Quote of the day"}
        </DialogTitle>
        <DialogContent  sx={styles.content}>
          <Avatar alt="img" src={IMAGES_LIST + quote.quotes_image} sx={{ width: 100, height: 100 }} />
          <DialogContentText id="alert-dialog-description" sx={styles.contenttext}>
            {quote.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={styles.actions}>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            sx={styles.button}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default QuotesDialogBox;
