import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";

function Footer() {
  return (
    <Box
      sx={{
        mt: "20px",
      }}
    >
      <footer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: " #869099", fontWeight: "bolder" }}>
            &copy;{" "}
            <Link
              href="https:/xminds.com"
              underline="hover"
              sx={{ color: "#262d5c" }}
            >
              Xminds
            </Link>
          </Typography>
          <Typography sx={{ color: " #869099", fontWeight: "bolder" }}>
            Version v1.0
          </Typography>
        </Box>
      </footer>
    </Box>
  );
}

export default Footer;
