import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IMAGES_LIST } from "./Constants";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Box, color } from "@mui/system";
import { Divider } from "@mui/material";
import ImageGallery from "./ImageGallery";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, background: "#E9DCC9" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline", background: "#E9DCC9" }}
      onClick={onClick}
    />
  );
}

function ImageCarousel({ open, onClose, selectedImage, data }) {
  const settings = {
    arrows: true,
    prevArrow: <SampleNextArrow />,
    nextArrow: <SamplePrevArrow />,
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
          },
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            marginBottom: "5px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <FontAwesomeIcon icon={faXmark} onClick={onClose} />
        </Box>
        <Slider {...settings}>
          {data &&
            data.map((obj) => (
              <img src={IMAGES_LIST + selectedImage} height="500px" />
            ))}
        </Slider>
      </DialogContent>
    </Dialog>
  );
}

export default ImageCarousel;
