import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import Service from "../../services";
import { useLocation, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import SnackBar from "../../Common/SnackBar";

const styles = {
  typo1: {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  checkboxlabel: {
    "& .MuiTypography-root": {
      fontWeight: 600,
      color: "rgba(39,44,51,.7)!important",
    },
  },
  label: {
    fontSize: "14px",
    color: "rgba(39,44,51,.7)!important",
  },
  uploadBox: {
    backgroundColor: "#f2f8ff",
    minWidth: 150,
    height: 65,
    borderRadius: 2,
    border: "1px dashed #262d5c",
  },
  boxTypo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cancelButton: {
    backgroundColor: "#f8f9fa",
    color: "#444",
  },
};
function AddAlbum() {
  const navigate = useNavigate();
  const [error, setError] = useState();

  const { state } = useLocation();
  const [snackOpen, setSnackOpen] = useState(false);

  const initialValues = {
    id: state.id,
    image: "",
  };
  const handleCancel = () => {
    navigate(-1);
  };
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  }; 
  const addQuotesSchema = object({});
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: addQuotesSchema,
      onSubmit: async (values) => {
        try {
          const res = await Service.addAlbumImage(values);
          navigate("/album", {
            state: {
              message: res.data.message,
              open:true
            },
          });
        } catch (error) {
          setSnackOpen(true)
          setError(error.response.data.error);
        }
      },
    });
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 1.5, mt: 8, width: "calc(100vw - 260px)" }}
    >
      <Typography style={styles.typo1}>Upload</Typography>
      <Card
        sx={{
          maxHeight: "auto",
          mt: 1,
          borderRadius: "10px",
        }}
      >
        <CardContent
          sx={{
            color: "rgba(39,44,51,.7)!important",
            fontSize: "14px",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ mt: 2 }}>
              <Typography sx={styles.label}>Upload Image:</Typography>
              <label htmlFor="fileInput">
                <Box sx={styles.uploadBox}>
                {values.image === "" ? (
              <Typography width="100%" height="100%" sx={styles.boxTypo}>
                UPLOAD A FILE
              </Typography>
            ) : (
              <Typography width="100%" height="100%" sx={styles.boxTypo}>
                CHANGE IMAGE
              </Typography>
            )}
                </Box>
              </label>
              <input
                id="fileInput"
                type="file"
                name="image"
                style={{ display: "none" }}
                onChange={(event) => {
                  const file = event.target.files[0];
                  setFieldValue("image", file);
                }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Button variant="contained" onClick={handleSubmit}>
                Save
              </Button>
              &nbsp;
              <Button
                variant="contained"
                onClick={handleCancel}
                style={styles.cancelButton}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        errorMsg={error}
      />
    </Box>
  );
}

export default AddAlbum;
