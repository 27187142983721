import * as React from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate, useParams } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import { object, string, ref } from "yup";
import { useFormik } from "formik";
import Service from "../../services";
import { useState } from "react";
import SnackBar from "../../Common/SnackBar";

export default function ResetPassword() {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const emailId = new URLSearchParams(window.location.search).get("email");
  const { token } = useParams();
  const [snackOpen, setSnackOpen] = useState(false);

  const navigate = useNavigate();
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const styles = {
    typo: {
      fontSize: "18px",
      fontWeight: 600,
    },
    login: {
      display: "flex",
      justifyContent: "center",
      mt: 2,
    },
    btn: {
      backgroundColor: " #000737",
      width: "320px",
      mt: 3,
    },
    sucesscard: {
      height: "50px",
      backgroundColor: "#28a745",
      color: "#fff",
    },
    successmsg: {
      paddingBottom: "10px",
    },
    errorcard: {
      height: "50px",
      backgroundColor: "#dc3545",
      color: "#fff",
    },
    errormsg: {
      paddingBottom: "10px",
    },
  };
  const initialValues = {
    email: emailId,
    password: "",
    password_confirmation: "",
  };
  const validationSchema = object().shape({
    email: string().email().required("Email is required"),
    password: string()
      .min(8, "Password must be at least 8 characters")
      .required("New Password is required"),
    password_confirmation: string()
      .oneOf([ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: validationSchema,
      onSubmit: async (values, action) => {
        values.token = token;
        try {
          const res = await Service.resetPassword(values);
          setSnackOpen(true);
          setSuccess(res.data.message);
        } catch (error) {
          setSnackOpen(true);
          setError(error.response.data.message);
        }
        action.resetForm();
      },
    });

  return (
    <Box component="main">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 15,
        }}
      >
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Leave Management System
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card sx={{ maxWidth: "360px", width: "100%" }}>
          <CardContent>
            <Box>
              <Box
                sx={{
                  margin: 0,
                  padding: "0px 20px 20px",
                  textAlign: "center",

                  color: "black",
                }}
              >
                <Typography sx={{ fontSize: "14px" }}>
                  You are only one step a way from your new password, recover
                  your password now.
                </Typography>
              </Box>
              <Grid>
                <TextField
                  name="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    style: {
                      height: "40px",
                      width: "320px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.email && touched.email ? (
                  <Typography fontSize="small" color="#dc3545">
                    {errors.email}
                  </Typography>
                ) : null}
              </Grid>
              <Grid>
                <TextField
                  type="password"
                  name="password"
                  placeholder="New Password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    style: {
                      height: "40px",
                      width: "320px",
                      marginTop: "10px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && touched.password ? (
                  <Typography fontSize="small" color="#dc3545">
                    {errors.password}
                  </Typography>
                ) : null}
              </Grid>
              <Grid>
                <TextField
                  type="password"
                  name="password_confirmation"
                  value={values.password_confirmation}
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    style: {
                      height: "40px",
                      width: "320px",
                      marginTop: "10px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password_confirmation &&
                touched.password_confirmation ? (
                  <Typography fontSize="small" color="#dc3545">
                    {errors.password_confirmation}
                  </Typography>
                ) : null}
              </Grid>
              <Button
                variant="contained"
                sx={{ backgroundColor: " #000737", width: "320px", mt: 3 }}
                onClick={handleSubmit}
              >
                Reset Password
              </Button>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Button size="small" onClick={() =>  navigate("/login", { replace: true })}>
                Login
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        messageName={success}
        errorMsg={error}
      />
    </Box>
  );
}
