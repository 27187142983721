import { Logout, Person } from "@mui/icons-material";
import {
  Avatar,
  Box,
  CssBaseline,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {  IMAGES_LIST } from "./Constants";
import Service from "../Components/services";
import Header from "./Header";
document.body.style = "background: #f4f5fd";

function Layout() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = anchorEl;
  const profileImage = localStorage.getItem("profileimage");
  const name = localStorage.getItem("firstName");
  const JoinedOn = localStorage.getItem("joinedOn");
  const ConvertedJoinedOn = moment(JoinedOn).format("MMM.YYYY");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfileClick = () => {
    navigate("/profile", { replace: true });
  };
  const logout = async () => {
    await Service.logout();
    localStorage.clear();
    navigate("/login", { replace: true });
  };
  

  return (
    <>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <CssBaseline />

        <Header
          handleClick={handleClick}
          openMenu={openMenu}
          name={name}
          profileImage={profileImage}
        />
        <Outlet />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          "& .MuiMenu-list": {
            paddingTop: "0px",
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            width: "280px",
            borderRadius: "5px",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: "78px",
              height: "78px",
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(80, 102, 224, 0.8)",
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
          }}
        >
          <MenuItem
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src={IMAGES_LIST + profileImage}
              sx={{ border: "0.1px solid white" }}
            />
          </MenuItem>

          <MenuItem sx={{ color: "#fff", font: "14px 'Inter', sans-serif" }}>
            Member since {ConvertedJoinedOn}
          </MenuItem>
        </Box>
        <Divider />
        <MenuItem
          sx={{ font: "14px 'Inter', sans-serif" }}
          onClick={handleProfileClick}
        >
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem sx={{ font: "14px 'Inter', sans-serif" }} onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Signout
        </MenuItem>
      </Menu>
    </>
  );
}

export default Layout;
