import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import axios from "axios";
import { useFormik } from "formik";
import moment from "moment";
import Service from "./services";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { date, number, object, string } from "yup";
import { API_LINK, BACKEND_API_LINK } from "./Constants";
import SnackBar from "./Common/SnackBar";
import { Troubleshoot } from "@mui/icons-material";

function ApplyCompo() {
  const navigate = useNavigate();
  const token = localStorage.getItem("usertoken");
  const [snackOpen, setSnackOpen] = useState(false);
  const [reportingUser, setReportingUser] = useState([]);
  const [error, setError] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    const url = new URL(window.location.href);
    const baseUrl = url.origin;
    if (baseUrl) {
      setUrl(baseUrl);
    } else {
    }
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_API_LINK}${API_LINK.getreportinguserslist}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const filteredManager = response.data.filter((item) => {
        const roles = item.role.split(",");
        return roles.includes("manager");
      });
      const filteredEmployees = response.data.filter((item) => {
        const roles = item.role.split(",");
        return roles.includes("lead") && !roles.includes("manager");
      });
      const filteredItem = filteredManager.concat(filteredEmployees);
      setReportingUser(
        filteredItem.map(({ first_name }) => " " + first_name + " ").toString()
      );
    } catch (error) {}
  };

  const initialValues = {
    compo_date: null,
    compo_project: "",
  };
  const styles = {
    typo1: {
      fontSize: "1.8rem",
      fontWeight: 700,
    },
    cardTypo: {
      fontSize: "14px",
      fontWeight: 600,
      color: "rgba(39,44,51,.7)",
    },
    cancelButton: {
      backgroundColor: "#f8f9fa",
      color: "#444",
    },
    ButtonBox: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      mt: 2,
    },
    reportingUserCard: {
      height: "50px",
      backgroundColor: "#6c757d",
      color: "#fff",
    },
    errorcard: {
      height: "50px",
      backgroundColor: "#dc3545",
      color: "#fff",
    },
    errormsg: {
      paddingBottom: "10px",
    },
  };
  const applyCompoSchema = object({
    compo_date: date().required("The compo date field is required"),
    compo_project: string().required("The compo project field is required"),
  });
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const { values, setFieldValue, errors, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: applyCompoSchema,
      onSubmit: async (values) => {
        try {
          values.url = url;
          const res = await Service.applyCompo(values);
          navigate("/listCompo", {
            state: {
              message: res.data.message,
              open:true,
            },
          });
        } catch (error) {
          // Handle errors here
          setSnackOpen(true);
          setError(error.response.data.message);
        }
      }
      
    });
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 2, mt: 8, width: "calc(100vw - 260px)" }}
    >

      <Typography style={styles.typo1}>Apply for Compo</Typography>
      <Card
        sx={{
          maxHeight: "1000px",
          mt: 1,
          borderRadius: "10px",
        }}
      >
        <CardContent
          sx={{
            color: "rgba(39,44,51,.7)!important",
            fontSize: "14px",
          }}
        >
          <Card sx={styles.reportingUserCard}>
            <CardContent>
              <Typography>
                <b>Hi</b> {reportingUser}
              </Typography>
            </CardContent>
          </Card>

          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              sx={{ paddingTop: "15px" }}
            >
              <Grid item xs={3.5}>
                <Typography sx={styles.cardTypo}>
                  I'm working on the date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    views={['year', 'month', 'day']}
                    name="compo_date"
                    format="YYYY-MM-DD"
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        error: errors.compo_date,
                        helperText: touched.compo_date && errors.compo_date,
                      },
                    }}
                    value={
                      values.compo_date
                        ? moment(values.compo_date, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) =>
                      setFieldValue(
                        "compo_date",
                        value ? value.format("YYYY-MM-DD") : "",
                        true
                      )
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3.5}>
                <Typography sx={styles.cardTypo}>For the project.</Typography>
                <TextField
                  name="compo_project"
                  fullWidth
                  type="string"
                  size="small"
                  value={values.compo_project}
                  error={errors.compo_project}
                  onChange={handleChange}
                  helperText={touched.compo_project && errors.compo_project}
                />
              </Grid>
            </Grid>
            <Divider sx={{ mt: 2 }} />
            <Box sx={styles.ButtonBox}>
              <Button
                variant="contained"
                style={styles.cancelButton}
                onClick={() => navigate("/dashboard")}
              >
                Cancel
              </Button>
              &nbsp;
              <Button variant="contained" onClick={handleSubmit}>
                Apply
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        errorMsg={error}
      />
    </Box>
  );
}

export default ApplyCompo;
