import "../App.css";
import {
  Box,
  Card,
  CardMedia,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import log from "../Images/bg-log.png";
import formlogo from "../Images/xminds_logo-black.png";
import { useState } from "react";
import Service from "../Components/services";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const initialValues = {
    email: "",
    password: "",
  };
  const handlePassword = () => {
    navigate("/login/password");
  };
  const loginSchema = object({
    email: string().email().required("Email is required"),
    password: string().min(8).required("Password is required"),
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: loginSchema,
      onSubmit: async (values, action) => {
        try {
          const res = await Service.login(values);
          const {
            id,
            access_token,
            refresh_token,
            profile_image,
            first_name,
            middle_name,
            last_name,
            joined_on,
          } = res.data;
          localStorage.setItem("id", id);
          localStorage.setItem("usertoken", access_token);
          localStorage.setItem("profileimage", profile_image);
          localStorage.setItem(
            "name",
            `${first_name} ${middle_name} ${last_name}`
          );
          localStorage.setItem("firstName",first_name)
          const roleRes = await Service.getRole();
          const role = roleRes.data[0].Name;
          localStorage.setItem("role", role);

          localStorage.setItem("joinedOn", joined_on);

          navigate("/dashboard", { replace: true });
        } catch (error) {
          setError(error.response.data.message);
        }

        action.resetForm();
      },
    });

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          height: "500px",
          width: "1024px",
          margin: "center",
          borderRadius: "10px",

          background: "rgb(247, 250, 255)",
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            marginTop: "35px",
            marginLeft: "30px",
          }}
        >
          <Grid item md={6}>
            <CardMedia
              component="img"
              image={log}
              alt="Logo"
              height="384px"
              width="380px"
              sx={{ objectFit: "contain" }}
            />
          </Grid>

          <Grid item md={6}>
            <Card
              maxHeight="500px"
              sx={{ width: "377px", borderRadius: "10px" }}
            >
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: "20px",
                  }}
                >
                  <Grid item md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <img
                          src={formlogo}
                          alt="Xminds"
                          height="54"
                          width="180"
                        />
                      </Box>
                      <Box sx={{ fontWeight: "bold", fontSize: "20px" }}>
                        <Typography class="typo">
                          Sign in to start your session
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      type="email"
                      placeholder="Email"
                      name="email"
                      size="small"
                      fullWidth="100%"
                      value={values.email}
                      error={errors.email || error}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <Typography fontSize="small" color="#dc3545">
                        {errors.email}
                      </Typography>
                    ) : null}
                    {error && (
                      <Typography fontSize="small" color="#dc3545">
                        {error}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item md={12}>
                    <FormControl sx={{ width: "100%" }} variant="outlined">
                      <OutlinedInput
                        size="small"
                        name="password"
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        error={errors.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        helperText={touched.password && errors.password}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: "#888",
                      }}
                    >
                      <FormControlLabel
                        value="remember me"
                        control={<Checkbox />}
                        label={
                          <span style={{ fontSize: "15px" }}>Remember Me</span>
                        }
                        labelPlacement="end"
                      />
                      <Typography
                        sx={{ fontSize: "15px", cursor: "pointer" }}
                        onClick={handlePassword}
                      >
                        I forgot my password
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <Button
                      variant="contained"
                      fullWidth="100%"
                      sx={{ backgroundColor: "#000737" }}
                      onClick={handleSubmit}
                    >
                      SIGN IN
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
export default Login;
