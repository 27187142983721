import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Footer from "./Footer";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
} from "@mui/material";
import banner from "../Images/banner.jpg";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMugHot,faChampagneGlasses,faEnvelope } from '@fortawesome/free-solid-svg-icons'

function Dashboard() {
const navigate = useNavigate();
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
      <Card sx={{ borderRadius: "10px",maxHeight:360 }}>
        <CardMedia component="img" image={banner} alt="banner" />
      </Card>
      <Grid container spacing={2} sx={{ mt: 0.5 }}>
        <Grid item md={4}>
          <Card
            onClick={()=>navigate("/holidays", { replace: true })}
            sx={{
              backgroundColor: "#6259ca",
              height: "110px",
              minWidth: 270,
              borderRadius: "15px",
              cursor: "pointer",
              opacity: 0.85,
              ":hover": { opacity: 1 },
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#fff ",
                }}
              >
                <Typography sx={{ml:"13%",mt:3}}>
                <FontAwesomeIcon icon={faChampagneGlasses} size="2xl" />
                </Typography>
                <Typography
                  sx={{ fontSize: 16,ml:"13%" ,mt:3}}
                  color="#fff "
                  fontWeight="bold"
                >
                  HOLIDAYS
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4}>
          <Card
            sx={{
              backgroundColor: "#20c997",
              height: "110px",
              minWidth: 270,
              borderRadius: "15px",
              alignItems: "center",
              cursor: "pointer",
              opacity: 0.85,
              ":hover": { opacity: 1 },
            }}
            onClick={()=>navigate("/applyforleave", { replace: true })}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems:"center",
                  color: "#fff ",
                  // mt:3
                }}
              >
                <Typography sx={{ml:"12%",mt:2.5}} >
                <FontAwesomeIcon icon={faMugHot} size="2xl" />                
                </Typography>
                <Typography
                  sx={{ fontSize: 16,ml:"12%",mt:3 }}
                  color="#fff "
                  fontWeight="bold"
                >
                  APPLY LEAVE
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4}>
          <Card
            sx={{
              backgroundColor: "#dc3545",
              height: "110px",
              minWidth: 270,
              borderRadius: "15px",
              cursor: "pointer",
              opacity: 0.85,
              ":hover": { opacity: 1 },
            }}
            onClick={()=>navigate("/appliedleaves", { replace: true })}
          >
            <CardContent sx={{padding:"20px"}}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#fff ",
                  // mt:3
                }}
              >
                <Typography sx={{ml:"12%",mt:2.3}}>
                <FontAwesomeIcon icon={faEnvelope} size="2xl"/>
                </Typography>
                <Typography sx={{ fontSize: 16,ml:"12%" ,mt:3}} fontWeight="bold">
                  APPLIED LEAVES
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Footer />
    </Box>
  );
}

export default Dashboard;
