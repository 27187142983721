import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IMAGES_LIST } from "../Constants";
import Service from "../services";
import SnackBar from "../Common/SnackBar";

import { useLocation } from "react-router-dom";

const style = {
  sucesscard: {
    height: "50px",
    backgroundColor: "#28a745",
    color: "#fff",
  },
  successmsg: {
    paddingBottom: "10px",
    mt: -1.5,
  },
  errorcard: {
    height: "50px",
    backgroundColor: "#dc3545",
    color: "#fff",
  },
  errormsg: {
    paddingBottom: "10px",
    mt: -1.5,
  },
  cardList: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    // ":hover": { borderBottom: "0.1px solid rgba(0,0,0,.125)" },
  },
  remove: {
    fontSize: "12px",
    mt: -2,
    p: "4px 15px 3px",
    borderColor: "#f34343",
    color: "#f34343",
    ":hover": {
      color: "#fff !important",
      backgroundColor: "#f34343",
      borderColor: "#f34343",
    },
  },
};

function ReportingUser({ userID }) {
  const [reportingUser, setReportingUser] = useState([]);
  const [success, setSuccess] = useState("");
  const [menuId, setMenuId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState("");
  const { state } = useLocation();
  const [snackOpen, setSnackOpen] = useState(false);
  const [error,setError]= useState("")

  const [allReportingUsersList, setAllReportingUsersList] = useState([]);
  async function AllReportingUsers() {
    try {
      const res = await Service.getAllReportingUsers(inputValue);
      setAllReportingUsersList(res.data);
    } catch (error) {
      setError(error.response.data.message);
    }
  }
  const handleFocus = (e) => {
    AllReportingUsers();
  };
  const handleUser = (e) => {
    setInputValue(e.target.value);
  };
  const handleChange = (e, val) => {
    setValue(val);
  };

  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const handleDelete = async (val) => {
    try {
      const res = await Service.deleteReportingUser(val);
      setError("");
      setSuccess(res.data.message);
      setSnackOpen(true);
      await AllReportingUsers();
      await getReportingUsers();
    } catch (error) {
      setSuccess("")
      setError(error.response.data.error);
      setSnackOpen(true);
      await AllReportingUsers();
      await getReportingUsers();
      // setError(error.response.data.message);
    }
  };
const addReportingUsers =() => {
  const data = {
    user_id: userID,
    reporting_user_id: value?.Id,
    created_by_user_id: userID,
  };
  Service.addReportingUser(data)
    .then((res) => {
      setError("");
      setValue(null);
      setInputValue("");
      AllReportingUsers();
      getReportingUsers();
      setSnackOpen(true);
      setSuccess(res.data.message);    
    })
    .catch((error) => {
      setSuccess("")
      setInputValue("");
      setSnackOpen(true);
      setError(error.response.data.error);
      setValue(null);
      AllReportingUsers();
      getReportingUsers();
    });
}
  const handleClick = (e) => {
    if(value) {
      addReportingUsers();
    }else {

    }
  
  };

  const getReportingUsers = async () => {
    try {
      const res = await Service.getReportingUsers(userID);
      setReportingUser(res.data);
    } catch (error) {
      setError(error.response.data.message);
    }
  };
  useEffect(() => {
    AllReportingUsers();
    getReportingUsers();
  }, [userID]);
  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          "& .MuiGrid-item": {
            paddingTop: "10px",
          },
        }}
      >
        <Grid item md={6}>
          <Card
            sx={{
              maxHeight: "auto",
              maxWidth: "750px",
              mt: 2,
              ml: 2.5,
              borderRadius: "10px",
              border: "0.1px solid #dadce0",
            }}
          >
            <CardContent>
              <Box sx={{ height: "40px" }}>
                <Typography
                  sx={{ fontSize: "18px", color: "#202124", fontWeight: 500 }}
                >
                  Reports To
                </Typography>
              </Box>
              <Divider />
              {reportingUser.map((item) => (
                <Box sx={style.cardList}>
                  <Grid container>
                    <Grid
                      item
                      md={2.5}
                      sx={{
                        "& .MuiGrid-item": {
                          paddingLeft: "0px",
                        },
                      }}
                    >
                      <Avatar
                        alt="profileimage"
                        src={IMAGES_LIST + item.profile_image}
                        sx={{ width: 60, height: 60 }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Typography sx={{ fontSize: "18px" }} variant="h3">
                        {`${item.first_name} ${item.middle_name} ${item.last_name}`}
                      </Typography>
                      <Typography>
                        <small>{item.email}</small>
                      </Typography>
                      <Typography>
                        <small>
                          <strong>{item.employee_id}</strong>
                        </small>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Button
                    variant="outlined"
                    onClick={() => handleDelete(item.id)}
                    sx={style.remove}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card
            sx={{
              maxHeight: "auto",
              maxWidth: "750px",
              mt: 2,
              mr: 2,
              borderRadius: "10px",
              border: "0.1px solid #dadce0",
            }}
          >
            <CardContent
              sx={{
                color: "rgba(39,44,51,.7)!important",
                fontSize: "14px",
              }}
            >
              <Box sx={{ height: "40px" }}>
                <Typography
                  sx={{ fontSize: "18px", color: "#202124", fontWeight: 500 }}
                >
                  Reporting User Id
                </Typography>
              </Box>
              <Divider />
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item md={10}>
                  <Autocomplete
                    blurOnSelect={true}
                    disableClearable
                    fullWidth
                    value={value === "" ? null : value}
                    size="small"
                    id="demo-simple-select"
                    options={allReportingUsersList}
                    getOptionLabel={(type) =>
                      type.FirstName + "-" + type.EmployeeId
                    }
                    onChange={handleChange}
                    onFocus={handleFocus}
                    renderInput={(params) => (
                      <TextField
                        onChange={handleUser}
                        {...params}
                        label="Select a User"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={2}>
                  <Button variant="contained" onClick={handleClick}>
                    Add
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <SnackBar
          openSnackerBar={snackOpen}
          handleCloseSnackBar={handleCloseSnackbar}
          messageName={success}
          errorMsg={ error}
        />
      </Grid>
    </Box>
  );
}

export default ReportingUser;
