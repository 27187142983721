import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import Service from "../services";
import SnackBar from "../Common/SnackBar";
const style = {
  sucesscard: {
    height: "50px",
    backgroundColor: "#28a745",
    color: "#fff",
  },
  successmsg: {
    paddingBottom: "10px",
    mt: -1.5,
  },
  errorcard: {
    height: "50px",
    backgroundColor: "#dc3545",
    color: "#fff",
  },
  errormsg: {
    paddingBottom: "10px",
    mt: -1.5,
  },
  cardList: {
    mt: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  revoke: {
    fontSize: "12px",
    mr: "20%",
    p: "4px 15px 3px",
    borderColor: "#f34343",
    color: "#f34343",
    ":hover": {
      color: "#fff !important",
      backgroundColor: "#f34343",
      borderColor: "#f34343",
    },
  },
};
function Roles({ userID }) {
  const [userRole, setUserRole] = useState([]);
  const [allRole, setAllRole] = useState([]);
  const [roleAssign, setRoleAssign] = useState([]);
  const [value, setValue] = useState("");
  const [success, setSuccess] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);

  const role = localStorage.getItem("role");
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleRoleClick =() => {
    roleAssigned();
    getUserRoles();
  }
  const handleDelete = async (value) => {
    const role = userRole.find((role) => role.Name === value);
    const data = {
      roleid: role.Id,
      modelid: userID,
    };
    
    try {
      const res = await Service.deleteRole(data);
      setSuccess({ value: res.data.message, isSuccess: true });
      setSnackOpen(true)
      await getUserRoles();
    } catch (error) {
      setSnackOpen(true)
      setSuccess({ value: error.response.data.error, isSuccess: false });
      await getUserRoles();
    }
  };
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const handleClick = async () => {
    const role = allRole.find((role) => role.Name === value);
    const data = {
      roleid: role.Id,
      modelid: userID,
    };
  
    try {
      const res = await Service.addRole(data);
      setSuccess({ value: res.data.message, isSuccess: true });
      setSnackOpen(true)
      await getUserRoles();
      setValue("");
    } catch (error) {
      setSuccess({ value: error.response.data.error, isSuccess: false });
      setSnackOpen(true)
      await getUserRoles();
      setValue("");
    }
  };
  
  const roleAssigned = async () => {
    try {
      const res = await Service.getRoleAssign(userID);
      setRoleAssign(res.data);
    } catch (error) {
      // setError(error.response.data.message);
    }
  };
  
  const getUserRoles = async () => {
    try {
      const res = await Service.getUserRole(userID);
      setUserRole(res.data);
    } catch (error) {
      // setError(error.response.data.message);
    }
  };
  const getAllRoles = async () => {
    try {
      const res = await Service.getAllRole();
      setAllRole(res.data);
    } catch (error) {
      // setError(error.response.data.message);
    }
  };
  
  useEffect(() => {
    getUserRoles();
    getAllRoles();
      roleAssigned();
  }, [userID]);
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          "& .MuiGrid-item": {
            paddingLeft: "15px",
            paddingTop: "10px",
          },
        }}
      >
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Card
            sx={{
              maxHeight: "auto",
              minWidth: "200px",
              mt: 2,
              ml: 2.5,
              borderRadius: "10px",
              border: "0.1px solid #dadce0",
            }}
          >
            <CardContent>
              <Box sx={{ height: "40px" }}>
                <Typography
                  sx={{ fontSize: "18px", color: "#202124", fontWeight: 500 }}
                >
                  Contact info
                </Typography>
              </Box>
              <Divider />
              {userRole.map((item) => (
                <Box sx={style.cardList}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(39,44,51,.7)!important",
                      fontWeight: 550,
                    }}
                  >
                    {item.Name[0].toUpperCase() + item.Name.slice(1)}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() => handleDelete(item.Name)}
                    sx={style.revoke}
                  >
                    Revoke
                  </Button>
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Card
            sx={{
              maxHeight: "auto",
              minWidth: "200px",
              mt: 2,
              mr: 2,
              borderRadius: "10px",
              border: "0.1px solid #dadce0",
            }}
          >
            <CardContent
              sx={{
                color: "rgba(39,44,51,.7)!important",
                fontSize: "14px",
              }}
            >
              <Box sx={{ height: "40px" }}>
                <Typography
                  sx={{ fontSize: "18px", color: "#202124", fontWeight: 500 }}
                >
                  Assign Role
                </Typography>
              </Box>
              <Divider />
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item md={10}>
                  <FormControl fullWidth size="small">
                    <TextField
                    size="small"
                      select
                      label="Select a role"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={value}
                      onFocus={handleRoleClick}
                      onChange={handleChange}
                      disabled={role === "manager" ? true : false}
                    >
                      {roleAssign.map((type) => (
                        <MenuItem id={type.Id} value={type.Name}>
                          {type.Name[0].toUpperCase() + type.Name.slice(1)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <Button variant="contained" onClick={handleClick}>
                    Add
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        messageName={success.isSuccess ? success.value : ""}
        errorMsg={success.isSuccess? "" :success.value}
      />
      </Grid>
    </>
  );
}

export default Roles;
