import { Box, Card, CardContent, Typography } from "@mui/material";
import Service from "../../services";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import QuotesAddEdit from "../../Common/QuotesAddEdit";
import SnackBar from "../../Common/SnackBar";

const styles = {
  typo1: {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  errorcard: {
    height: "50px",
    backgroundColor: "#dc3545",
    color: "#fff",
  },
  errormsg: {
    paddingBottom: "10px",
  },
};
function EditQuote() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [snackOpen, setSnackOpen] = useState(false);
  const id = state.id;
  const [error, setError] = useState("");
  const initialValues = {
    description: state.description,
    status: state.status,
    image: state.image,
  };
  const editQuoteSchema = object({
    description: string().required("The description field is required"),
  });
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const editQuote = async (value) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("description", value.description);
    formData.append("status", value.status);
    formData.append("image", value.image);
    try {
      const res = await Service.editQuote(formData);
      navigate("/quotes", {
        state: {
          message: res.data.message,
          open:true,
        },
      });
    } catch (error) {
      setSnackOpen(true)
      setError(error.response.data.message);
    }
  };
  
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 1.5, mt: 8, width: "calc(100vw - 260px)" }}
    >
      <Typography style={styles.typo1}>Edit Quote</Typography>
      <Card
        sx={{
          maxHeight: "auto",
          mt: 1,
          borderRadius: "10px",
        }}
      >
        <CardContent
          sx={{
            color: "rgba(39,44,51,.7)!important",
            fontSize: "14px",
          }}
        >
          <QuotesAddEdit
            QuotesSchema={editQuoteSchema}
            initialValues={initialValues}
            fetchData={editQuote}
          />
        </CardContent>
      </Card>
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        errorMsg={error}
      />
    </Box>
  );
}

export default EditQuote;
