import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Info from "./Info";
import UpdateDetails from "./UpdateDetails";
import ChangePassword from "./ChangePassword";
import Leave from "./Leave";
import moment from "moment";
import Compo from "./Compo";
import Roles from "./Roles";
import ReportingUser from "./ReportingUser";
import LeaveList from "./LeaveList";
import Service from "../services";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

export default function ProfileTab({
  Edit,
  viewUserDetails,
  id,
  initialValues,
  setViewUserDetails,
  setUserDetails,
}) {
  const role = localStorage.getItem("role");
  const [value, setValue] = useState(1);
  const getEmployeeDetail = async () => {
    try {
      const res = await Service.getEmployeeDetails(id);
      setViewUserDetails(res.data);
    } catch (error) {
    }
  };
  const getUserDetails = async () => {
    try {
      const res = await Service.getUserdetails();
      const birthday = res.data.dob;
      const JoinedOn = res.data.joined_on
      const convertedBirthday = moment(birthday).format("YYYY-MM-DD");
      const convertedJoinedOn = moment(JoinedOn).format("YYYY-MM-DD");
      setUserDetails({
        firstName: res.data.first_name,
        middleName: res.data.middle_name,
        lastName: res.data.last_name,
        gender: res.data.gender,
        bloodGroup: res.data.blood_group,
        employeeId: res.data.employee_id,
        designation: res.data.designation,
        email: res.data.name,
        mobileNo: res.data.mobile_no,
        birthday: convertedBirthday,
        joinedOnDate: convertedJoinedOn,
      });
    } catch (error) {
    }
  };
  const handleChange = (event, newValue) => {
    if(newValue === 1){  
       if(Edit === "profile"){
        getUserDetails();
       }
       getEmployeeDetail();
    }else {
     
    }
    setValue(newValue);
  };

  useEffect(() => {
    getEmployeeDetail();  
  }, [id]);
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          sx={{
            "& .MuiTab-root": {
              color: "#6c757d",
              fontFamily: "'Inter', sans-serif",
            },
            maxWidth: 1500,
          }}
        >
          <Tab label="Info" value={1} />
          <Tab label="Update Details" value={2} />
          <Tab label="Change Password" value={3} />
          {(role === "admin" || role === "manager") && (
            <Tab label="Roles" value={4} />
          )}
          {(role === "admin" || role === "manager") && (
            <Tab label="Reporting Person" value={5} />
          )}
          {(role === "admin" || role === "manager") && (
            <Tab label="Leave List" value={6} />
          )}
          <Tab label="Leave" value={7} />
          <Tab label="Compo" value={8} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={1} sx={{ mt: 2 }}>
        <Info userDetails={viewUserDetails} Edit={Edit} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UpdateDetails
          userDetails={viewUserDetails}
          userID={id}
          setValue={setValue}
          Edit={Edit}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ChangePassword userid={id} Edit={Edit} initialValues={initialValues} />
      </TabPanel>
      {(role === "admin" || role === "manager") && (
        <TabPanel value={value} index={4}>
          <Roles userID={id} />
        </TabPanel>
      )}
      {(role === "admin" || role === "manager") && (
        <TabPanel value={value} index={5}>
          <ReportingUser userID={id} userDetails={viewUserDetails} />
        </TabPanel>
      )}
      {(role === "admin" || role === "manager") && (
        <TabPanel value={value} index={6}>
          <LeaveList userID={id} Edit={Edit} userDetails={viewUserDetails} />
        </TabPanel>
      )}

      <TabPanel value={value} index={7}>
        <Leave ids={id} Edit={Edit} />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Compo ids={id} userDetails={viewUserDetails}  Edit={Edit}/>
      </TabPanel>
    </Box>
  );
}
