import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { setNestedObjectValues } from "formik";
import React, { useEffect, useState } from "react";
import SnackBar from "../Common/SnackBar";

import { LeaveListHeader } from "../Constants";
import Service from "../services";
const style = {
  sucesscard: {
    height: "50px",
    backgroundColor: "#28a745",
    color: "#fff",
  },
  successmsg: {
    paddingBottom: "10px",
    mt: -1.5,
  },
  errorcard: {
    height: "50px",
    backgroundColor: "#dc3545",
    color: "#fff",
  },
  errormsg: {
    paddingBottom: "10px",
    mt: -1.5,
  },
  tableBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
    width: "188px",
  },
  remove: {
    fontSize: "12px",
    p: "4px 15px 3px",
    borderColor: "#f34343",
    color: "#f34343",
    ":hover": {
      color: "#fff !important",
      backgroundColor: "#f34343",
      borderColor: "#f34343",
    },
  },
  save: {
    fontSize: "12px",
    p: "4px 15px 3px",
    borderColor: "#38cab3",
    color: "#38cab3",
    ":hover": {
      color: "#fff !important",
      backgroundColor: "#38cab3",
      borderColor: "#38cab3",
    },
  },
};
function LeaveList({ userID }) {
  const [leavetype, setLeaveType] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);

  const [allLeaveTypes, setAllLeaveTypes] = useState([]);
  const [val, setVal] = useState("");
  const [success, setSuccess] = useState({});
  const getAllLeaveTypes = async () => {
    try {
      const res = await Service.getAllLeaveTypes();
      setAllLeaveTypes(res.data.data);
    } catch (error) {
      // setError(error.response.data.message);
    }
  };
  const getListTypeEmployee = async (id) => {
    try {
      const res = await Service.getListTypeEmployee(id);
      setLeaveType(res.data);
    } catch (error) {}
  };
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  useEffect(() => {
    getListTypeEmployee(userID);
    getAllLeaveTypes();
  }, [userID]);
  const options = allLeaveTypes.map(
    (item) => item.LeaveTypeTitle + "-" + item.LeaveCount
  );
  const handleSubmit = async (value) => {
    const leaveData = {
      userid: userID,
      type: value.id,
      leavecount: parseFloat(value.leave_count),
    };

    try {
      const res = await Service.updateLeaveCount(leaveData);
      setSuccess({ value: res.data.message, isSuccess: true });
      setSnackOpen(true)
      await getListTypeEmployee(userID);
    } catch (error) {
      setSuccess({ value: error.response.data.message, isSuccess: false });
      setSnackOpen(true)
      await getListTypeEmployee(userID);
    }
  };

  const handleChange = (ind, e) => {
    setLeaveType((prevState) => {
      const updatedLeaveType = [...prevState];
      updatedLeaveType[ind].leave_count = e.target.value;
      return updatedLeaveType;
    });
  };
  const handleAdd = async () => {
    const id = allLeaveTypes.find((users) => users.LeaveTypeTitle === val.split("-")[0]);
    const data = {
      created_by_user_id: userID,
      leave_type_id: id.Id,
      number_of_leaves: id.LeaveCount,
      user_id: userID,
    };

    try {
      const res = await Service.addLeaveType(data);
      setSuccess({ value: res.data.message, isSuccess: true });
      setSnackOpen(true)
      await getListTypeEmployee(userID);
      await getAllLeaveTypes();
      setVal("")
    } catch (error) {
      setSnackOpen(true)
      setSuccess({ value: error.response.data.error, isSuccess: false });
      await getListTypeEmployee(userID);
      await getAllLeaveTypes();
      setVal("")
    }
  };

  const handleRemove = async (value) => {
    const removeData = {
      userid: userID,
      type: value.id,
    };
    try {
      const res = await Service.removeLeaveType(removeData);
      setSnackOpen(true)
      setSuccess({ value: res.data.error, isSuccess: true });
      await getListTypeEmployee(userID);
    } catch (error) {
      setSnackOpen(true)
      setSuccess({ value: error.response.data.error, isSuccess: false });
      await getListTypeEmployee(userID);
    }
  };

  return (
    <Box>

      <form>
        <Grid
          container
          spacing={3}
          sx={{
            "& .MuiGrid-item": {
              paddingLeft: "15px",
              paddingTop: "10px",
            },
          }}
        >
          <Grid item md={6}>
            <Card
              sx={{
                maxHeight: "auto",
                maxWidth: "auto",
                mt: 2,
                ml: 2.5,
                borderRadius: "10px",
                border: "0.1px solid #dadce0",
              }}
            >
              <CardContent>
                <Box sx={{ height: "40px" }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#202124",
                    }}
                  >
                    List of available leaves
                  </Typography>
                </Box>
                <Divider />
                <Box sx={{ mt: 1 }}>
                  {leavetype?.map((row, index) => (
                    <TableContainer
                      component={Box}
                      sx={{ borderRadius: "10px", mt: 2, overflowX: "hidden" }}
                    >
                      <Table aria-label="simple table">
                        <TableHead sx={{ background: "#c2d0ff" }}>
                          <TableRow>
                            {LeaveListHeader.map((item) => (
                              <TableCell key={item.id}>{item.label}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ width: "100%", maxWidth: "100px" }}
                            >
                              {row.leave_type_title}
                            </TableCell>
                            <TableCell align="right">
                              <Box sx={style.tableBox}>
                                <TextField
                                  name="leavecount"
                                  type="number"
                                  sx={{
                                    width: "100%",
                                    minWidth: "100px",
                                  }}
                                  value={row.leave_count}
                                  InputProps={{
                                    sx: { height: "27px" },
                                    inputProps: {
                                      min: 0,
                                      step: 0.5,
                                    },
                                  }}
                                  onChange={(event) =>
                                    handleChange(index, event)
                                  }
                                />
                                &nbsp;
                                <Button
                                  sx={style.save}
                                  variant="outlined"
                                  onClick={() => handleSubmit(row)}
                                >
                                  Save
                                </Button>
                              </Box>
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                variant="outlined"
                                onClick={() => handleRemove(row)}
                                sx={style.remove}
                              >
                                Remove
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6}>
            <Card
              sx={{
                maxHeight: "auto",
                maxWidth: "750px",
                mt: 2,
                mr: 2,
                borderRadius: "10px",
                border: "0.1px solid #dadce0",
              }}
            >
              <CardContent>
                <Box sx={{ height: "40px" }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#202124",
                      fontWeight: 500,
                    }}
                  >
                    Leave Type Id
                  </Typography>
                </Box>
                <Divider />
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item md={10}>
                    <Autocomplete
                      size="small"
                      disablePortal
                      freeSolo
                      autoComplete
                      value={val}
                      id="combo-box-demo"
                      options={options}
                      disableClearable
                      onChange={(event, newValue) => {
                        setVal(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Leave Type" />
                      )}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Button variant="contained" onClick={handleAdd}>
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        messageName={success.isSuccess ? success.value : ""}
        errorMsg={success.isSuccess? "" :success.value}

      />
        </Grid>
      </form>
    </Box>
  );
}

export default LeaveList;
