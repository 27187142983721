import { Snackbar, IconButton, Slide, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useEffect } from "react";

function TransitionRight(props) {
  return <Slide {...props} direction="left" />;
}
export default function SnackBar(props) {
  const {
    openSnackerBar,
    errorMsg,
    handleCloseSnackBar,
    messageName,
    errorTittleName,
  } = props;
  const action = (
    <IconButton
      aria-label="close"
      color="inherit"
      onClick={handleCloseSnackBar}
    >
      <CloseIcon sx={{ fontSize: "1.2rem" }} />
    </IconButton>
  );
  return (
    <Snackbar
      sx={{
        bottom: "50px !important",
        zIndex: "1500 !important",
        "& .MuiSnackbarContent-root": {
          ...(errorMsg && {
            width: "460px",
            minHeight: "76px",
            maxHeight: "90px",
            backgroundColor: "#f44336 !important",
          }),
          padding: "12px 12px",
        },
        "& .MuiSnackbarContent-message": {
          padding: "0px",
          fontWeight: "300",
        },
        "& .MuiSnackbarContent-action": {
          p: "0",
        },
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={openSnackerBar}
      autoHideDuration={4000}
      onClose={handleCloseSnackBar}
      TransitionComponent={TransitionRight}
      action={action}
      message={
        errorMsg ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around !important",
              width: "100%",
              ml: "3px",
              alignItems: "center",
            }}
          >
            <ErrorOutlineIcon
              sx={{
                width: "22px !important",
                height: "22px !important",
                mr: "13px",
                letterSpacing: "0.031em",
                alignSelf: "flex-start",
              }}
            />
            <Box sx={{ width: "356px" }}>
              <Typography sx={{ fontWeight: 500 }}>
                {" "}
                {errorTittleName}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {errorMsg}
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <CheckCircleOutlineOutlinedIcon
              sx={{ mr: "10px", mb: "-8px", letterSpacing: "0.031em" }}
            />
            {messageName}
          </>
        )
      }
    ></Snackbar>
  );
}
