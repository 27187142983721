import {
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { useState } from "react";
import { useFormik } from "formik";
import { date, number, object, ref, string } from "yup";
import axios from "axios";
import { API_LINK, BACKEND_API_LINK } from "./Constants";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Service from "./services";
import QuotesDialogBox from "./QuotesDialogBox";
import SnackBar from "./Common/SnackBar";

function ApplyForLeave() {
  const navigate = useNavigate();
  const [leaveType, setLeaveType] = useState([]);
  const [error, setError] = useState("");
  const [url, setUrl] = useState("");
  const [allQuote, setAllQuote] = useState("");
  const token = localStorage.getItem("usertoken");
  const [snackOpen, setSnackOpen] = useState(false);
  const id = localStorage.getItem("id")
  const initialValues = {
    number_of_days: "",
    start: null,
    end: null,
    type: "",
    back_to_work_date: null,
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const styles = {
    typo1: {
      fontSize: "1.8rem",
      fontWeight: 700,
    },
    cancelButton: {
      backgroundColor: "#f8f9fa",
      color: "#444",
    },
    card: {
      cardTypo: {
        fontSize: "14px",
        fontWeight: 600,
        color: "rgba(39,44,51,.7)",
      },
      daysTypo: {
        fontSize: "14px",
        fontWeight: 600,
        color: "rgba(39,44,51,.7)",
        ml: 2,
      },
    },
    Grid: {
      pt: "5px",
    },
    daysBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    errorcard: {
      height: "50px",
      backgroundColor: "#dc3545",
      color: "#fff",
    },
    errormsg: {
      paddingBottom: "10px",
    },
  };
  const handleDateChange = (val) => {
    const Date = moment(val).format("YYYY-MM-DD");
    setFieldValue("start", Date);
  };
  const applyLeaveSchema = object({
    number_of_days: number().required("The number of days field is required"),
    start: date().required("The start field is required"),
    end: date()
      .min(ref("start"), "The end must be a date after or equal to start")
      .required("The end field is required"),
    type: string().required("The type field is required"),
    back_to_work_date: date()
      .min(
        ref("end"),
        "The back to work date must be a date after or equal to end."
      )
      .required("The back to work date field is required"),
  });

  const { values, setFieldValue, errors, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: applyLeaveSchema,
      onSubmit: async (values) => {
        try {
          values.url = url;
          const res = await Service.ApplyLeave(values);
          navigate("/appliedleaves", {
            state: {
              message: res.data.message,
              open:true,
            },
          });
        } catch (error) {
          // Handle errors here
          setSnackOpen(true);
          setError(error.response.data.message);
        }
      }
      
    });
    const getAllQuotes = async () => {
      try {
        const res = await Service.getQuote();
        setAllQuote(res.data.data);
        setOpen(true);
      } catch (error) {
      }
      setOpen(true);
    };
    const handleCloseSnackbar = (event, reason) => {
      setSnackOpen(false);
      if (reason === "timeout") {
        setSnackOpen(false);
      }
    };
    const getListTypeEmployee = async () => {
      try {
        const res = await Service.getListTypeEmployee(id);
        setLeaveType(res.data);
      } catch (error) { 
      }
    };
  useEffect(() => {
    const url = new URL(window.location.href);
    const baseUrl = url.origin;
    if (baseUrl) {
      setUrl(baseUrl);
    } else {
    }
    getAllQuotes();
    getListTypeEmployee();
  }, []);

  return (
    <>
      <QuotesDialogBox
        openDialog={open}
        handleCloseDialog={handleClose}
        quote={allQuote}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
        <Typography sx={styles.typo1}>Add Leave</Typography>
        <Card
          sx={{
            maxHeight: "1000px",
            mt: 2,
            borderRadius: "10px",
          }}
        >
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} sx={styles.Grid}>
                <Grid item md={6}>
                  <Typography sx={styles.card.cardTypo}>
                    I hereby request you to grant me leave for
                  </Typography>
                  <Box sx={styles.daysBox}>
                    <TextField
                      name="number_of_days"
                      fullWidth
                      size="small"
                      type="number"
                      value={values.number_of_days}
                      error={errors.number_of_days}
                      InputProps={{ inputProps: { min: 0.5, step: 0.5 } }}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      helperText={
                        touched.number_of_days && errors.number_of_days
                      }
                    />
                    <Typography sx={styles.card.daysTypo}>Day(s)</Typography>
                  </Box>
                </Grid>
                <Grid item md={3}>
                  <Typography sx={styles.card.cardTypo}>
                    From the date
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      name="start"
                      format="YYYY-MM-DD"
                      views={['year', 'month', 'day']}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          error: errors.start,
                          helperText: touched.start && errors.start,
                        },
                      }}
                      value={
                        values.start ? moment(values.start, "YYYY-MM-DD") : null
                      }
                      onChange={(value) =>
                        setFieldValue(
                          "start",
                          value ? value.format("YYYY-MM-DD") : "",
                          true
                        )
                      }
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={3}>
                  <Typography sx={styles.card.cardTypo}>
                    Till the date
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      name="end"
                      format="YYYY-MM-DD"
                      views={['year', 'month', 'day']}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          error: errors.end,
                          helperText: touched.end && errors.end,
                        },
                      }}
                      value={
                        values.end ? moment(values.end, "YYYY-MM-DD") : null
                      }
                      onChange={(value) =>
                        setFieldValue(
                          "end",
                          value ? value.format("YYYY-MM-DD") : "",
                          true
                        )
                      }
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6}>
                  <Typography sx={styles.card.cardTypo}>
                    For the purpose of
                  </Typography>
                  <FormControl fullWidth size="small"  sx={{mt:1}}>
                    <InputLabel id="demo-simple-select-label">
                      Select a type
                    </InputLabel>
                    <Select
                      name="type"
                      label="Select a type"
                      id="demo-simple-select"
                      value={values.type}
                      error={errors.type}
                      onChange={handleChange}
                    >
                      {leaveType.map((type) => (
                        <MenuItem id={type.id} value={type.id}>
                          {type.leave_type_title}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.type && errors.type ? (
                      <FormHelperText
                        sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                      >
                        {touched.type && errors.type}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <Typography sx={styles.card.cardTypo}>
                    I will be back to work on
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                    sx={{mt:1}}
                      name="back_to_work_date"
                      format="YYYY-MM-DD"
                      views={['year', 'month', 'day']}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          error: errors.back_to_work_date,
                          helperText:
                            touched.back_to_work_date &&
                            errors.back_to_work_date,
                        },
                      }}
                      value={
                        values.back_to_work_date
                          ? moment(values.back_to_work_date, "YYYY-MM-DD")
                          : null
                      }
                      onChange={(value) =>
                        setFieldValue(
                          "back_to_work_date",
                          value ? value.format("YYYY-MM-DD") : "",
                          true
                        )
                      }
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 2 }} />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Button
                  variant="contained"
                  style={styles.cancelButton}
                  onClick={() => navigate("/dashboard")}
                >
                  Cancel
                </Button>
                &nbsp;
                <Button variant="contained" onClick={handleSubmit}>
                  Add
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
        <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        errorMsg={error}
      />
      </Box>
    </>
  );
}

export default ApplyForLeave;
