import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import React from "react";
import { useNavigate } from "react-router-dom";

const styles = {
  boxCheck: {
    display: "flex",
    flexDirection: "column",
    mt: 1,
    label: {
      fontSize: "14px",
      color: "rgba(39,44,51,.7)!important",
    },
  },
  label: {
    fontSize: "14px",
    color: "rgba(39,44,51,.7)!important",
  },
  checkboxlabel: {
    "& .MuiTypography-root": {
      fontWeight: 600,
      color: "rgba(39,44,51,.7)!important",
    },
  },
  cancelButton: {
    backgroundColor: "#f8f9fa",
    color: "#444",
  },
};
function LeaveTypeAddEdit({ initialValues, leaveTypeSchema, fetchData }) {
  const navigate = useNavigate();
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: leaveTypeSchema,
      onSubmit: (values) => {
        fetchData(values);
      },
    });
    const handleCancel = () => {
      navigate("/leaveTypes", { replace: true });
    };
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Typography sx={styles.label}>Title:</Typography>
          <TextField
            name="title"
            fullWidth
            size="small"
            value={values.title}
            error={errors.title}
            onChange={handleChange}
            helperText={touched.title && errors.title}
          />
        </Grid>
        <Grid item md={6}>
          <Typography sx={styles.label}>Number of leaves:</Typography>
          <TextField
            name="number_of_leaves"
            fullWidth
            size="small"
            type="number"
            value={values.number_of_leaves}
            // error={errors.number_of_days}
            onChange={handleChange}
            // helperText={touched.number_of_days && errors.number_of_days}
          />
        </Grid>
        <Grid item md={12}>
          <Typography sx={styles.label}>Description:</Typography>
          <TextField
            name="description"
            fullWidth
            size="small"
            multiline
            minRows={10}
            value={values.description}
            error={errors.description}
            onChange={handleChange}
            helperText={touched.description && errors.description}
          />
        </Grid>
      </Grid>
      <Box sx={styles.boxCheck}>
        <FormControlLabel
          name="is_active"
          control={
            <Checkbox
              sx={{ transform: "scale(0.8)" }}
              checked={values.is_active}
              onChange={(event) => {
                const isChecked = event.target.checked;
                setFieldValue("is_active", isChecked === true ? 1 : 0);
              }}
            />
          }
          label={<span sx={{ fontSize: "14px" }}>Is Active</span>}
          sx={styles.checkboxlabel}
        />
        <FormControlLabel
          name="is_core"
          control={
            <Checkbox
              sx={{ transform: "scale(0.8)" }}
              checked={values.is_core}
              onChange={(event) => {
                const isChecked = event.target.checked;
                setFieldValue("is_core", isChecked === true ? 1 : 0);
              }}
            />
          }
          label={<span sx={{ fontSize: "14px" }}>Is Core</span>}
          sx={styles.checkboxlabel}
        />
        <FormControlLabel
          name="is_compo"
          control={
            <Checkbox
              sx={{ transform: "scale(0.8)" }}
              checked={values.is_compo}
              onChange={(event) => {
                const isChecked = event.target.checked;
                setFieldValue("is_compo", isChecked === true ? 1 : 0);
              }}
            />
          }
          label={<span sx={{ fontSize: "14px" }}>Is Compo</span>}
          sx={styles.checkboxlabel}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button>
        &nbsp;
        <Button variant="contained" style={styles.cancelButton} onClick={handleCancel}>
          Cancel
        </Button>
      </Box>
    </form>
  );
}

export default LeaveTypeAddEdit;
