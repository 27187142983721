export const API_LINK = {
  login: "/login",
  getrole: "/get-role",
  refresh : "/refresh",
  me: "/me",
  listholiday: "/list-holiday",
  logout: "/logout",
  applyleave: "/apply-leave",
  listleavetype: "/list-leave-type",
  appliedleavelist: "/applied-leave-list",
  applycompo: "/apply-compo",
  appliedcompolist: "/applied-compo-list",
  getreportinguserslist: "/get-reporting-users-list",
  getalbumlistdata: "/get-album-list-data",
  getalbumimagelist: "/get-album-image-list",
  listleavedatauser: "/list-leave-data-user",
  listcompodatauser: "/list-compo-data-user",
  useruploadfile: "/user_upload_file/profile",
  passwordchange: "/password-change",
  userprofileupdate: "/user-profile-update",
  listallemployees: "/list-all-employees",
  listallleads: "/list-all-leads",
  listallmanagers: "/list-all-managers",
  listallalumni: "/list-all-alumni",
  listleavetypes: "/list-leave-types",
  listallquote: "/list-all-quote",
  addemployee: "/add-employee",
  addrole: "/add-role",
  createleavetype: "/create-leave-type",
  createnewquote: "/create-new-quote",
  createnewalbum: "/create-new-album",
  createnewholiday: "/create-new-holiday",
  editquote: "/edit-quote",
  editholiday: "/edit-holiday",
  editleavetype: "/edit-leave-type",
  editalbum: "/edit-album",
  deletequote: "/delete-quote",
  deletealbum: "/delete-album",
  deleterole: "/delete-role",
  getallquotelist: "/get-quote",
  deleteholiday: "/delete-holiday",
  deleteemployee: "/delete-employee",
  deleteleavetype: "/delete-leave-type",
  getemployeedetail: "/get-employee-detail",
  comboModerationDetails: "/combo-moderation-details",
  leaveModerationDetails: "/leave--moderation-details",
  editemployee: "/edit-employee",
  editemployeedetail: "/edit-employee-detail",
  getuserrole: "/get-user-role",
  getallrole: "/get-all-roles",
  getreportingusers: "/get-reporting-users",
  useravailableleaves: "/user-available-leaves",
  listallleavetypes: "/list-leave-types",
  updateleavecount: "/update-leave-count",
  getallreportinguserslist: "/get-all-reporting-users-list",
  addReportingUser: "/add-reporting-user",
  leavemoderatorationleadlist: "/leave-moderation-lead-list",
  compomoderatorationleadlist: "/combo-moderation-lead-list",
  addalbumimage: "/add-album-image",
  deleteleavemoderator: "/delete-leave-moderation",
  deletecombomoderator: "/delete-combo-moderation",
  getroleassign: "/get-roles-assign",
  deletereportinguser: "/delete-reporting-user",
  editprofileimage: "/edit-profile-image",
  editemployeeimage: "/edit-employee-image",
  approverejectleave: "/approve_reject-leave",
  approverejectcompo: "/approve_reject-compo",
  listleaveemployee: "/list-leave-employee",
  listcompoemployee: "/list-compo-employee",
  removeleavetype: "/remove-leave-type",
  changepasswordemployee: "/change-password-employee",
  listleavetypeemployee: "/list-leave-type-employee",
  resetleave: "/reset-leave",
  forgotpassword: "/forgot-password",
  updatenewpassword: "/update-new-password",
  addleavetype: "/add-leave-type",
  deletealbumimage: "/delete-album-image",
  listleavetypesdata: "/list-leave-types-data"
};
export const LEAVETYPEHEAD = {
  Title: "Title",
  No: "Number of leave",
  Des: "Description",
  Active: "isActive",
  Core: "isCore",
  Compo: "isCompo",
};
export const HOLIDAYHEAD = {
  title: "Title",
  date: "Date",
  des: "Description",
  status: "Status",
};
export const LEAVEMODERATORSHEAD = {
  Leave_Type: "Leave Type",
  Start_Date: "Start Date",
  User_Email: "User Email",
  Status: "Status",
  Number_Of_Leaves: "Number Of Leaves",
  End_Date: "End Date",
  Back_to_work_Date: "Back to work Date",
  Compo_Leave: "Compo Leave",
  User_Name: "User Name",
  Remarks: "Remarks",
};
export const COMPOMODERATORSHEAD = {
  Compo_Project: "Compo Project",
  Compo_Date: "Compo Date",
  User_Name: "User Name",
  User_Email: "User Email",
  Remarks: "Remarks",
  Status: "Status",
};
export const QUOTEHEAD = {
  des: "Description",
  image: "Image",
  status: "Status",
};
export const LEAVEMODERATORS = "Leave Moderator Details";
export const COMPOMODERATORS = "Compo Moderator Details";
export const HOLIDAY = "Holiday Details";
export const LEAVETYPE = "View Leave Type";
export const QUOTE = "Quote Details";
export const LIST_EMPLOYEE = [
  "Dashboard",
  "Holidays",
  "Apply for Leave",
  "Applied Leaves",
  "Apply for Compo",
  "Applied Compo's",
  "Image Gallery",
];
export const LIST_LEAD = [
  "Dashboard",
  "Holidays",
  "Apply for Leave",
  "Applied Leaves",
  "Apply for Compo",
  "Applied Compo's",
  "Image Gallery",
  "Leave Moderators",
  "Compo Moderators",
];
export const LIST_MANAGER = [
  "Dashboard",
  "Employees",
  "Lead",
  "Manager",
  "Alumini",
  "Leave Types",
  "Quotes",
  "Holidays",
  "Apply for Leave",
  "Applied Leaves",
  "Reset Leaves",
  "Apply for Compo",
  "Applied Compo's",
  "Image Gallery",
  "Leave Moderators",
  "Compo Moderators",
];
export const DESIGNATION = [
  { value: "Project Manager", label: "Project Manager" },
  {
    value: "Business Development Manager",
    label: "Business Development Manager",
  },
  {
    value: "Business Development Executive",
    label: "Business Development Executive",
  },
  { value: "QA Manager", label: "QA Manager" },
  {
    value: "Delivery and Operations Manager",
    label: "Delivery and Operations Manager",
  },
  { value: "HR Manager", label: "HR Manager" },
  { value: "Project Lead", label: "Project Lead" },
  { value: "QA Lead", label: "QA Lead" },
  { value: "Software Developer", label: "Software Developer" },
  { value: "QA Engineer", label: "QA Engineer" },
  { value: "Admin", label: "Admin" },
  { value: "Devops Engineer", label: "Devops Engineer" },
  { value: "Architect", label: "Architect" },
  { value: "Senior Developer", label: "Senior Developer" },
  { value: "Senior Devops Engineer", label: "Senior Devops Engineer" },
  { value: "Senior QA", label: "Senior QA" },
  { value: "Senior Admin", label: "Senior Admin" },
  { value: "Senior Architect", label: "Senior Architect" },
  { value: "Junior Developer", label: "Junior Developer" },
  { value: "Junior QA", label: "Junior QA" },
  { value: "Junior Architect", label: "Junior Architect" },
  { value: "Junior Admin", label: "Junior Admin" },
  { value: "Junior Devops Engineer", label: "Junior Devops Engineer" },
  { value: "Junior Admin", label: "Junior Admin" },
  {
    value: "Business Development Trainee",
    label: "Business Development Trainee",
  },
  { value: "Developer Trainee", label: "Developer Trainee" },
  { value: "Devops Trainee", label: "Devops Trainee" },
  { value: "Admin Trainee", label: "Admin Trainee" },
  { value: "QA Trainee", label: "QA Trainee" },
];
export const GENDER = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Others",
    label: "Others",
  },
];
// export const PTAB_LIST = [
//   "Info",
//   "Update Details",
//   "Change Password",
//   "Leave",
//   "Compo",
// ];
export const PTAB_LIST = [
  { label: "Info", value: 1, role: ["admin", "manager", "lead", "employee"] },
  {
    label: "Update Details",
    value: 2,
    role: ["admin", "manager", "lead", "employee"],
  },
  {
    label: "Change Password",
    value: 3,
    role: ["admin", "manager", "lead", "employee"],
  },
  { label: "Roles", value: 4, role: ["admin", "manager"] },
  { label: "Reporting Person", value: 5, role: ["admin", "manager"] },
  { label: "Leave List", value: 6, role: ["admin", "manager"] },
  { label: "Leave", value: 7, role: ["admin", "manager", "lead", "employee"] },
  { label: "Compo", value: 8, role: ["admin", "manager", "lead", "employee"] },
  // "Update Details",
  // "Change Password",
  // "Roles",o
  // "Reporting Person",
  // "Leave List",
  // "Leave",
  // "Compo",
];
export const BLOODGROUPS = [
  {
    value: "A+",
    label: "A+",
  },
  {
    value: "A-",
    label: "A-",
  },
  {
    value: "B+",
    label: "B+",
  },
  {
    value: "B-",
    label: "B-",
  },
  {
    value: "AB+",
    label: "AB+",
  },
  {
    value: "AB-",
    label: "AB-",
  },
  {
    value: "O+",
    label: "O+",
  },
  {
    value: "O-",
    label: "O-",
  },
];
export const entries = [
  {
    value: "10",
    label: "10",
  },
  {
    value: "25",
    label: "25",
  },
  {
    value: "50",
    label: "50",
  },
  {
    value: "100",
    label: "100",
  },
];
export const holidayTableHeader = [
  { id: 0, label: "title" },
  { id: 1, label: "description" },
  { id: 2, label: "date" },
  { id: 3, label: "status" },
  { id: 4, label: "action" },
];
export const listLeaveTableHeader = [
  { id: 0, label: "from", name: "start" },
  { id: 1, label: "to", name: "end" },
  { id: 2, label: "no. of leaves", name: "number_of_days" },
  { id: 3, label: "applied date", name: "created_at" },
  { id: 4, label: "type", name: "type" },
  { id: 5, label: "status", name: "status" },
];
export const listCompoTableHeader = [
  { id: 0, label: "compo date", name: "compo_date" },
  { id: 1, label: "compo project", name: "compo_project" },
  { id: 2, label: "applied date", name: "created_at" },
  { id: 3, label: "status", name: "status" },
];
export const listLeaveModeratorsTableHeader = [
  { id: 0, label: "type", name: "compo_date" },
  { id: 1, label: "email", name: "compo_project" },
  { id: 2, label: "reporting users", name: "created_at" },
  { id: 3, label: "start", name: "status" },
  { id: 4, label: "end", name: "status" },
  { id: 5, label: "number_of_days", name: "status" },
  { id: 6, label: "status", name: "status" },
  { id: 7, label: "action", name: "status" },
];
export const listCompoModeratorsTableHeader = [
  { id: 0, label: "email", name: "compo_date" },
  { id: 1, label: "reporting users", name: "compo_project" },
  { id: 2, label: "compo_project", name: "created_at" },
  { id: 3, label: "compo_date", name: "status" },
  { id: 4, label: "status", name: "status" },
  { id: 5, label: "action", name: "status" },
];
export const ListEmployeeTableHeader = [
  { id: 0, label: "name", name: "first_name" },
  { id: 1, label: "email", name: "email" },
  { id: 2, label: "employee_id", name: "employee_id" },
  { id: 3, label: "gender", name: "gender" },
  { id: 4, label: "dob", name: "dob" },
  { id: 5, label: "joined_on", name: "joined_on" },
  { id: 6, label: "employee_status", name: "employee_status" },
  { id: 7, label: "manager & leads", name: "manager & leads" },
  { id: 8, label: "action", name: "action" },
];
export const ListLeaveTypesTableHeader = [
  { id: 0, label: "leave_type_title", name: "compo_date" },
  { id: 1, label: "leave_type_description", name: "compo_project" },
  { id: 2, label: "is_active", name: "created_at" },
  { id: 3, label: "is_core", name: "status" },
  { id: 4, label: "is_compo", name: "status" },
  { id: 5, label: "leave_count", name: "status" },
  { id: 6, label: "action", name: "status" },
];
export const ListAllQuoteTableHeader = [
  { id: 0, label: "description", name: "compo_date" },
  { id: 1, label: "status", name: "compo_project" },
  { id: 2, label: "action", name: "created_at" },
];
export const LeaveListHeader = [
  { id: 0, label: "TITLE" },
  { id: 1, label: "NO.OF LEAVES" },
  { id: 2, label: "ACTION" },
];
export const DELETEQUOTE = "Quote";
export const DELETEALBUM = "Album";
export const DELETEHOLIDAY = "Holiday";
export const DELETELEAVEMODERATORS = "Leavemoderators";
export const DELETECOMPOMODERATORS = "Compomoderators";
export const DELETEEMPLOYEE = "Employee";
export const DELETELEAD = "Lead";
export const DELETEMANAGER = "Manager";
export const DELETELEAVETYPE = "Leavetype";
export const DELETEALUMINI = "Alumini";

export const PROFILE_LINK = "https://lmsgoapi-dev.xminds.in/";
export const IMAGES_LIST = "https://lmsgoapi-dev.xminds.in/avatar/images/";
export const ALBUM_COVER = "https://lmsgoapi-dev.xminds.in/avatar/albums/";
export const BACKEND_API_LINK = "https://lmsgoapi-dev.xminds.in/api/v1/auth";
// export const IMAGE = "http://lms-api.xminds.in/avatar/albums/"
// export const IMAGEGALLERY = "http://lms-api.xminds.in/avatar/imageGallery/"
