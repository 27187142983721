import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Avatar,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

import {
  API_LINK,
  BACKEND_API_LINK,
  DELETEEMPLOYEE,
  entries,
  IMAGES_LIST,
  ListEmployeeTableHeader,
} from "../../Constants";
import Footer from "../../Footer";
import AlertDialog from "../../Common/AlertDialog";
import Service from "../../services";
import SnackBar from "../../Common/SnackBar";

function Employees() {
  const token = localStorage.getItem("usertoken");
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [query, setQuery] = useState("");
  const [snackOpen, setSnackOpen] = useState(state?.open);
  const [order, setOrder] = useState("asc");
  const [field, setField] = useState("first_name");
  const [perPage, setPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  window.history.replaceState({}, document.title);
  const handleChange = (e, p) => {
    setPage(p);
  };
  useEffect(() => {
    listAllEmployees();
  }, [page, query, order, perPage]);
  const listAllEmployees = async () => {
    try {
      const res = await Service.listEmployee(page, perPage, query, order, field);
      setData(res.data.data);
      setPageCount(Math.ceil(res.data.count / perPage));
    } catch (error) {
    }
  };
  if (state) {
    setTimeout(() => {
      setSnackOpen(false)
    }, 2000);
  }
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };

  const handleSort = (column) => {
    const isAsc = field === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setField(column);
  };

  const handleFilter = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };
  const handleSearch = (e) => {
    setQuery(e.target.value);
    setPage(1);
  };
  const styles = {
    typo1: {
      fontSize: "1.8rem",
      fontWeight: 700,
    },
    action1: {
      fontSize: "10px",
      p: "6px 16px 5px",
      borderColor: "#5b67c7",
      color: "#5b67c7",
      ":hover": {
        color: "#fff !important",
        backgroundColor: "#5b67c7",
        borderColor: "#5b67c7",
      },
    },
    action2: {
      fontSize: "10px",
      p: "6px 16px 5px",
      borderColor: "#38cab3",
      color: "#38cab3",
      ":hover": {
        color: "#fff !important",
        backgroundColor: "#38cab3",
        borderColor: "#38cab3",
      },
    },
    action3: {
      fontSize: "10px",
      p: "6px 16px 5px",
      borderColor: "#dc3545",
      color: "#dc3545",
      ":hover": {
        color: "#fff !important",
        backgroundColor: "#dc3545",
        borderColor: "#dc3545",
      },
    },
    ".MuiTableCell-root ": {
      color: "#495057",
      fontFamily: "Inter, sans-serif",
    },
    tableCellNoData: {
      borderBottom: "none",
      color: "rgb(73, 80, 87)",
    },
    addNewButton: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sucesscard: {
      height: "50px",
      backgroundColor: "#28a745",
      color: "#fff",
    },
    errorcard: {
      height: "50px",
      backgroundColor: "#dc3545",
      color: "#fff",
    },
    successmsg: {
      paddingBottom: "10px",
    },
  };
  const handleClick = () => {
    navigate("/users/create", { replace: true });
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleDeleteClick = (id) => {
    setId(id);
    setOpen(true);
  };
  const handleDelete = async () => {
    try {
      const res = await Service.deleteEmployee(id);
      await listAllEmployees();
      setMsg(res.data.message);
      setSnackOpen(true);
      setOpen(false);
    } catch (error) {
      // Handle errors here
      // setError(error.response.data.message);
    }
  };  

  const handleEditClick = (id) => {
    navigate(`/users/${id}/edit`, {
      state: {
        value: id,
      },
    });
  };
  const handleViewClick = (id) => {
    navigate(`/users/${id}`, {
      state: {
        value: id,
      },
    });
  };
  return (


    <Box
      component="main"
      sx={{ flexGrow: 1, p: 1.5, mt: 8, width: "calc(100vw - 260px)" }}
    >
      <Box sx={styles.addNewButton}>
        <Typography style={styles.typo1}>Employee</Typography>
        <Button
          variant="contained"
          sx={{ padding: "6px 20px", fontSize: "12px" }}
          onClick={handleClick}
        >
          ADD NEW
        </Button>
      </Box>
      <Card
        sx={{
          maxHeight: "auto",
          mt: 1,
          borderRadius: "10px",
          border: "0.1px solid #dadce0",
        }}
      >
        <CardContent
          sx={{
            color: "rgba(39,44,51,.7)!important",
            fontSize: "14px",
          }}
        >
          <Grid
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Grid item xs={6} md={6}>
              <Typography>
                Show{" "}
                <span>
                  <TextField
                    id="outlined-select-currency"
                    select
                    defaultValue="10"
                    value={perPage}
                    onChange={handleFilter} // (e) => setPerPage(e.target.value)
                    inputProps={{
                      sx: {
                        paddingRight: "30px",
                        paddingTop: "3px",
                        paddingBottom: "3px",
                        fontSize: "75%",
                      },
                    }}
                  >
                    {entries.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </span>{" "}
                entries
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDiretion: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Typography>Search:</Typography>&nbsp;
                <TextField
                  size="small"
                  onChange={handleSearch} // (e) => setQuery(e.target.value)
                  inputProps={{
                    sx: {
                      height: "1em",
                    },
                  }}
                ></TextField>
              </Box>
            </Grid>
          </Grid>

          <TableContainer
            component={Box}
            sx={{ mt: 1.5, borderRadius: "10px", overflowX: "auto" }}
          >
            <Table
              sx={{ minWidth: 650, overflowX: "auto" }}
              aria-label="simple table"
            >
              <TableHead sx={{ background: "#c2d0ff" }}>
                <TableRow>
                  {ListEmployeeTableHeader.map((value) => (
                    <TableCell align="left" key={value.id}>
                      <TableSortLabel
                        active={field === value.name}
                        direction={field === value.name ? order : "asc"}
                        onClick={() => handleSort(value.name)}
                      >
                        {value.label.toUpperCase()}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data ? (
                  data.map((value) => (
                    <TableRow
                      key={value.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                     <TableCell component="th" scope="row" align="left">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar alt={value.first_name} src={IMAGES_LIST + value.profile_image} />
                          <span style={{ marginLeft: '8px' }}>{value.first_name}</span>
                        </div>
                      </TableCell>

                      <TableCell
                        align="left"
                        style={styles[".MuiTableCell-root "]}
                      //   width="30%"
                      >
                        {value.email}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={styles[".MuiTableCell-root "]}
                      //   width="30%"
                      >
                        {value.employee_id}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={styles[".MuiTableCell-root "]}
                      //   width="30%"
                      >
                        {value.gender}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={styles[".MuiTableCell-root "]}
                      //   width="30%"
                      >
                        {moment(value.dob).fromNow()}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={styles[".MuiTableCell-root "]}
                      //   width="30%"
                      >
                        {moment(value.joined_on).fromNow()}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={styles[".MuiTableCell-root "]}
                      //   width="30%"
                      >
                        {value.old_employee === false
                          ? "Current Employee"
                          : "Alumini"}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={styles[".MuiTableCell-root "]}
                      //   width="30%"
                      >
                        {value.reporting_users}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={styles[".MuiTableCell-root "]}
                      //   width="30%"
                      //   sx={{ display: "flex", flexDirection: "row" }}
                      >
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Button
                            variant="outlined"
                            sx={styles.action1}
                            onClick={() => handleViewClick(value.id)}
                          >
                            View
                          </Button>
                          &nbsp;
                          <Button
                            variant="outlined"
                            sx={styles.action2}
                            onClick={() => handleEditClick(value.id)}
                          >
                            Edit
                          </Button>
                          &nbsp;
                          <Button
                            variant="outlined"
                            sx={styles.action3}
                            onClick={() => handleDeleteClick(value.id)}
                          >
                            Delete
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      style={styles.tableCellNoData}
                    >
                      No Matching Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center-",
            }}
          >
            <Pagination
              count={pageCount}
              color="primary"
              shape="rounded"
              page={page}
              onChange={handleChange}
            />
          </Box>
        </CardContent>
      </Card>
      <AlertDialog
        open={open}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
        title={DELETEEMPLOYEE}
      />
        <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        messageName={msg ? msg : state?.message}
      />
      <Footer />
    </Box>

  );
}

export default Employees;
