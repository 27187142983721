import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { date, number, object, string } from "yup";
import EmployeeForm from "../../Common/EmployeeForm";
import Footer from "../../Footer";
import { useEffect } from "react";
import Service from "../../services";
import { useState } from "react";
import moment from "moment/moment";
import SnackBar from "../../Common/SnackBar";


function EditEmployee() {
  const token = localStorage.getItem("usertoken");
  const navigate = useNavigate();
  const { state } = useLocation();
  const [details, setDetails] = useState({});
  const [snackOpen, setSnackOpen] = useState(false);
  const [error, setError] = useState("");
  const isEdit = true;
  const initialValues = {
    id: details.id,
    first_name: details.first_name,
    middle_name: details.middle_name,
    last_name: details.last_name,
    gender: `${details.gender}`,
    blood_group: `${details.blood_group}`,
    employee_id: details.employee_id,
    designation: `${details.designation}`,
    email: details.name,
    mobile_no: details.mobile_no,
    dob: moment(details.dob),
    joined_on: moment(details.joined_on),
    old_employee: details.old_employee === true ? 1 : 0,
    reason_for_resignation: details.reason_for_resignation,
    date_of_resignation: null,
  };
  const updateSchema = object({
    first_name: string().required("FirstName is required"),
    gender: string().required("Gender is required"),
    email: string().email().required("Email is required"),
  });
  const styles = {
    typo1: {
      fontSize: "1.8rem",
      fontWeight: 700,
    },
  };
  const getEmployeeDetail = async () => {
    try {
      const id = state.value;
      const res = await Service.getEmployeeDetails(id);
      setDetails(res.data);
    } catch (error) {
      setError(error.response.data.message);
    }
  };
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const editEmployee = async (value) => {
    try {
      const res = await Service.editEmployee(value);
      
      let navigatePath = "/role/employee/users"; // Default path
      
      if (state.name === "lead") {
        navigatePath = "/role/lead/users";
      } else if (state.name === "manager") {
        navigatePath = "/role/manager/users";
      } else if (state.name === "alumini") {
        navigatePath = "/role/alumini/users";
      }
      navigate(navigatePath, {
        state: {
          message: res.data.message,
          open:true,
        },
      });
    } catch (error) {
      setSnackOpen(true)
      setError(error.response.data.message);
    }
  };
  
  useEffect(() => {
    getEmployeeDetail();
  }, []);
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 1.5, mt: 8, width: "calc(100vw - 260px)" }}
    >
      <Typography style={styles.typo1}>Edit User</Typography>
      <Card
        sx={{
          maxHeight: "auto",
          mt: 1,
          borderRadius: "10px",
        }}
      >
        <CardContent
          sx={{
            color: "rgba(39,44,51,.7)!important",
            fontSize: "14px",
          }}
        >
          <EmployeeForm
            initialValues={initialValues}
            isEdit={isEdit}
            name={state.name}
            fetchData={editEmployee}
          />
        </CardContent>
      </Card>
      <Footer />
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        errorMsg={error}
      />
    </Box>
  );
}

export default EditEmployee;
