import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { IMAGES_LIST } from "../Constants";
const styles = {
  checkboxlabel: {
    "& .MuiTypography-root": {
      fontWeight: 600,
      color: "rgba(39,44,51,.7)!important",
    },
  },
  label: {
    fontSize: "14px",
    color: "rgba(39,44,51,.7)!important",
  },
  uploadBox: {
    backgroundColor: "#f2f8ff",
    minWidth: 150,
    height: 65,
    borderRadius: 2,
    border: "1px dashed #262d5c",
  },
  boxTypo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cancelButton: {
    backgroundColor: "#f8f9fa",
    color: "#444",
  },
};
function QuotesAddEdit({ QuotesSchema, initialValues, fetchData }) {
  const navigate = useNavigate();
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: QuotesSchema,
    onSubmit: (values) => {
      fetchData(values);
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <Typography sx={styles.label}>Description:</Typography>
      <TextField
        name="description"
        fullWidth
        size="small"
        multiline
        minRows={10}
        value={values.description}
        error={errors.description}
        onChange={handleChange}
        helperText={touched.description && errors.description}
      />

      <Box sx={{ display: "flex" ,justifyContent:"space-evenly",mt:2}}>
        <FormControlLabel
        style={{marginRight:"900px"}}
          name="status"
          control={
            <Checkbox
              sx={{ transform: "scale(0.8)" ,}}
              checked={values.status}
              onChange={(event) => {
                const isChecked = event.target.checked;
                setFieldValue("status", isChecked ? 1 : 0);
              }}
            />
          }
          label={<span sx={{ fontSize: "14px" }}>Status</span>}
          sx={styles.checkboxlabel}
        />
        <Typography sx={styles.label}>
          Current Image:{" "}
          <img
            width="100"
            height="80"
            src={IMAGES_LIST + values.image}
            alt="image"
          />
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography sx={styles.label}>Upload Image:</Typography>
        <label htmlFor="fileInput">
          <Box sx={styles.uploadBox}>
            {values.image === "" ? (
              <Typography width="100%" height="100%" sx={styles.boxTypo}>
                UPLOAD A FILE
              </Typography>
            ) : (
              <Typography width="100%" height="100%" sx={styles.boxTypo}>
                CHANGE IMAGE
              </Typography>
            )}
          </Box>
        </label>
        <input
          id="fileInput"
          type="file"
          name="image"
          style={{ display: "none" }}
          onChange={(event) => {
            const file = event.target.files[0];
            setFieldValue("image", file);
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button>
        &nbsp;
        <Button
          variant="contained"
          style={styles.cancelButton}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </Box>
    </form>
  );
}

export default QuotesAddEdit;
