import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { GENDER, DESIGNATION, BLOODGROUPS } from "../Constants";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function EmployeeForm({
  error,
  userDetails,
  initialValues,
  fetchData,
  isAdd,
  updateSchema,
  isEdit,
  name,
}) {
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: updateSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let testobj = { ...values };

      Object.keys(testobj).forEach((key) => {
        if (key === "dob" || key === "joined_on") {
          testobj[key] = moment(testobj[key]).format("YYYY-MM-DD");
        }
      });
      // Object.maxHeighttestobj
      fetchData(testobj);
    },
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const styles = {
    cancelButton: {
      backgroundColor: "#f8f9fa",
      color: "#444",
    },
    "& .MuiFormControl-root": {
      width: "100% !important",
    },
  };
  const handleCheckboxClick = (e) => {
    const isChecked = e.target.checked;
    setFieldValue("old_employee", isChecked === true ? 1 : 0);
  };
  const handleCancel = () => {
    if (name === "lead") {
      navigate("/role/lead/users", { replace: true });
    } else if (name === "manager") {
      navigate("/role/manager/users", { replace: true });
    } else if (name === "alumini") {
      navigate("/role/alumini/users", { replace: true });
    } else {
      navigate("/role/employee/users", { replace: true });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={3}
        sx={{
          "& .MuiGrid-item": {
            paddingLeft: "15px",
            paddingTop: "10px",
          },
        }}
      >
        <Grid item md={6}>
          <Card
            sx={{
              maxHeight: "auto",
              maxWidth: "750px",
              mt: 2,
              ml: 2.5,
              borderRadius: "10px",
              border: "0.1px solid #dadce0",
            }}
          >
            <CardContent
              sx={{
                color: "rgba(39,44,51,.7)!important",
                fontSize: "14px",
              }}
            >
              <Box sx={{ height: "40px" }}>
                <Typography
                  sx={{ fontSize: "18px", color: "#202124", fontWeight: 500 }}
                >
                  Basic info
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  height: "63px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  ":hover": { borderBottom: "0.1px solid rgba(0,0,0,.125)" },
                }}
              >
                <Typography>First Name</Typography>
                <TextField
                  sx={{ width: "100%", maxWidth: "250px" }}
                  size="small"
                  name="first_name"
                  type="text"
                  error={errors.first_name}
                  value={values.first_name}
                  onChange={handleChange}
                  helperText={touched.first_name && errors.first_name}
                ></TextField>
              </Box>
              <Box
                sx={{
                  height: "63px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  ":hover": { borderBottom: "0.1px solid rgba(0,0,0,.125)" },
                }}
              >
                <Typography>Middle Name</Typography>
                <TextField
                  sx={{ width: "100%", maxWidth: "250px" }}
                  size="small"
                  name="middle_name"
                  value={values.middle_name}
                  error={errors.middle_name}
                  helperText={touched.middle_name && errors.middle_name}
                  onChange={handleChange}
                ></TextField>
              </Box>
              <Box
                sx={{
                  height: "63px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  ":hover": { borderBottom: "0.1px solid rgba(0,0,0,.125)" },
                }}
              >
                <Typography>Last Name</Typography>
                <TextField
                  sx={{ width: "100%", maxWidth: "250px" }}
                  size="small"
                  name="last_name"
                  value={values.last_name}
                  error={errors.last_name}
                  helperText={touched.last_name && errors.last_name}
                  onChange={handleChange}
                ></TextField>
              </Box>
              <Box
                sx={{
                  height: "63px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  ":hover": { borderBottom: "0.1px solid rgba(0,0,0,.125)" },
                }}
              >
                <Typography>Gender</Typography>
                <TextField
                  id="outlined-select-currency"
                  select
                  size="small"
                  name="gender"
                  sx={{ width: "100%", maxWidth: "250px" }}
                  value={values.gender}
                  error={errors.gender}
                  onChange={handleChange}
                  helperText={touched.gender && errors.gender}
                >
                  {GENDER.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box
                sx={{
                  height: "63px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  ":hover": { borderBottom: "0.1px solid rgba(0,0,0,.125)" },
                }}
              >
                <Typography>Blood Group</Typography>
                <TextField
                  select
                  sx={{ width: "100%", maxWidth: "250px" }}
                  size="small"
                  name="blood_group"
                  value={values.blood_group}
                  error={errors.blood_group}
                  onChange={handleChange}
                  helperText={touched.blood_group && errors.blood_group}
                >
                  {BLOODGROUPS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              {isAdd && (
                <Box
                  sx={{
                    height: "63px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    ":hover": { borderBottom: "0.1px solid rgba(0,0,0,.125)" },
                  }}
                >
                  <Typography>Password</Typography>
                  <FormControl
                    sx={{ width: "100%", maxWidth: "250px" }}
                    variant="outlined"
                  >
                    <OutlinedInput
                      size="small"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      error={errors.password}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.password && (
                      <div style={{ color: "#d32f2f",fontSize:"12px",marginLeft:"14px",marginTop:2 }}>{errors.password}</div>
                    )}
                  </FormControl>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card
            sx={{
              maxHeight: "auto",
              maxWidth: "750px",
              mt: 2,
              mr: 2,
              borderRadius: "10px",
              border: "0.1px solid #dadce0",
            }}
          >
            <CardContent
              sx={{
                color: "rgba(39,44,51,.7)!important",
                fontSize: "14px",
              }}
            >
              <Box sx={{ height: "40px" }}>
                <Typography
                  sx={{ fontSize: "18px", color: "#202124", fontWeight: 500 }}
                >
                  Employee info
                </Typography>
              </Box>
              <Divider />

              <Box
                sx={{
                  height: "63px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  ":hover": {
                    borderBottom: "0.1px solid rgba(0,0,0,.125)",
                  },
                }}
              >
                <Typography>Employee Id</Typography>
                <TextField
                  sx={{ width: "100%", maxWidth: "250px" }}
                  size="small"
                  name="employee_id"
                  error={errors.employee_id}
                  value={values.employee_id}
                  onChange={handleChange}
                  helperText={touched.employee_id && errors.employee_id}
                ></TextField>
              </Box>
              <Box
                sx={{
                  height: "63px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  ":hover": {
                    borderBottom: "0.1px solid rgba(0,0,0,.125)",
                  },
                }}
              >
                <Typography>Designation</Typography>
                <TextField
                  select
                  size="small"
                  name="designation"
                  sx={{ width: "100%", maxWidth: "250px" }}
                  value={values.designation}
                  error={errors.designation}
                  onChange={handleChange}
                  helperText={touched.designation && errors.designation}
                >
                  {DESIGNATION.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box
                sx={{
                  height: "63px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  ":hover": { borderBottom: "0.1px solid rgba(0,0,0,.125)" },
                }}
              >
                <Typography>Email</Typography>
                <TextField
                  sx={{ width: "100%", maxWidth: "250px" }}
                  size="small"
                  name="email"
                  error={errors.email}
                  value={values.email}
                  onChange={handleChange}
                  helperText={touched.email && errors.email}
                ></TextField>
              </Box>
              <Box
                sx={{
                  height: "63px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  ":hover": { borderBottom: "0.1px solid rgba(0,0,0,.125)" },
                }}
              >
                <Typography>Mobile No</Typography>
                <TextField
                  size="small"
                  sx={{ width: "100%", maxWidth: "250px" }}
                  // type="number"
                  name="mobile_no"
                  inputProps={{ maxLength: 10 }}
                  error={errors.mobile_no}
                  value={values.mobile_no}
                  onChange={handleChange}
                  helperText={touched.mobile_no && errors.mobile_no}
                ></TextField>
              </Box>
              <Box
                sx={{
                  height: "63px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  ":hover": { borderBottom: "0.1px solid rgba(0,0,0,.125)" },
                }}
              >
                <Typography>Birthday</Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    views={["year", "month", "day"]}
                    name="dob"
                    format="YYYY-MM-DD"
                    sx={{ width: "100%", maxWidth: "250px" }}
                    maxDate={moment().subtract(15, 'years')}
                    slotProps={{
                      textField: {
                        size: "small",
                        error: errors.dob,
                        helperText: touched.dob && errors.dob,
                      },
                    }}
                    value={values.dob}
                    onChange={(value) =>
                      setFieldValue("dob", moment(value), true)
                    }
                  />
                </LocalizationProvider>
              </Box>
              {/* {(isAdd || isEdit) && ( */}
              <Box
                sx={{
                  height: "63px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  ":hover": { borderBottom: "0.1px solid rgba(0,0,0,.125)" },
                }}
              >
                <Typography>Joining Date</Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    views={["year", "month", "day"]}
                    name="joined_on"
                    format="YYYY-M-DD"
                    // defaultValue={moment(userDetails.birthday)}
                    sx={{ width: "100%", maxWidth: "250px" }}
                    slotProps={{
                      textField: {
                        size: "small",
                        error: errors.joined_on,
                        helperText: touched.joined_on && errors.joined_on,
                      },
                    }}
                    value={values.joined_on}
                    onChange={(value) =>
                      setFieldValue("joined_on", moment(value), true)
                    }
                  />
                </LocalizationProvider>
              </Box>
              {/* )} */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box
        // width="100%"
        sx={{
          p: 1.1,
        }}
      >
        {isEdit && (
          <FormControl sx={styles["& .MuiFormControl-root"]}>
            <FormControlLabel
              name="old_employee"
              //  value={values.old_employee}

              control={
                <Checkbox
                  sx={{ transform: "scale(0.8)" }}
                  checked={values.old_employee}
                  onChange={handleCheckboxClick}
                />
              }
              label={<span sx={{ fontSize: "14px" }}>Set as Old Employee</span>}
              sx={styles.checkboxlabel}
            />
            {values.old_employee === 1 && (
              <Box>
                <Typography sx={{ fontSize: "14px" }}>
                  Date of Resigning
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    views={["year", "month", "day"]}
                    name="date_of_resignation"
                    sx={{ width: "100%", maxWidth: "250px" }}
                    format="YYYY-MM-DD"
                    // defaultValue={moment(userDetails.birthday)}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                    value={values.date_of_resignation}
                    onChange={(value) =>
                      setFieldValue(
                        "date_of_resignation",
                        moment(value).format("YYYY-MM-DD"),
                        true
                      )
                    }
                  />
                </LocalizationProvider>
                <Typography sx={{ mt: 1, fontSize: "14px" }}>
                  Reason for Resignation
                </Typography>
                <TextField
                  sx={{ width: "100%", maxWidth: "250px" }}
                  name="reason_for_resignation"
                  fullWidth
                  size="small"
                  multiline
                  minRows={10}
                  value={values.reason_for_resignation}
                  // error={errors.description}
                  onChange={handleChange}
                  // helperText={touched.description && errors.description}
                />
              </Box>
            )}
          </FormControl>
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Button
          variant="contained"
          style={styles.cancelButton}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        &nbsp;
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button>
      </Box>
    </form>
  );
}

export default EmployeeForm;
