import { Box, Card, CardContent, Typography } from "@mui/material";
import Service from "../../services";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { date, object, string } from "yup";
import HolidayAddEdit from "../../Common/HolidayAddEdit";
import { useState } from "react";
import SnackBar from "../../Common/SnackBar";

const styles = {
  typo1: {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  errorcard: {
    height: "50px",
    backgroundColor: "#dc3545",
    color: "#fff",
  },
  errormsg: {
    paddingBottom: "10px",
  },
};
function EditHoliday() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [snackOpen, setSnackOpen] = useState(false);
  const id = state.id;
  const [error, setError] = useState("");
  const initialValues = {
    date: state.date,
    description: state.description,
    id: id,
    status: state.status ? 1 : 0,
    title: state.title,
  };
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const editHolidaySchema = object({
    title: string().required("The title field is required").matches(/^[a-zA-Z\s]*$/,"Title must contain only letters"),
    date: date().required("The date field is required"),
  });
  const editHoliday = async (value) => {
    try {
      const res = await Service.editHoliday(value);
      navigate("/holidays", {
        state: {
          message: res.data.message,
          open:true,
        },
      });
    } catch (error) {
      setSnackOpen(true)
      setError(error.response.data.message);
    }
  };
  
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 1.5, mt: 8, width: "calc(100vw - 260px)" }}
    >

      <Typography style={styles.typo1}>Edit Holiday</Typography>
      <Card
        sx={{
          maxHeight: "auto",
          mt: 1,
          borderRadius: "10px",
        }}
      >
        <CardContent
          sx={{
            color: "rgba(39,44,51,.7)!important",
            fontSize: "14px",
          }}
        >
          <HolidayAddEdit
            holidaySchema={editHolidaySchema}
            initialValues={initialValues}
            fetchData={editHoliday}
          />
        </CardContent>
      </Card>
      <SnackBar
      openSnackerBar={snackOpen}
      handleCloseSnackBar={handleCloseSnackbar}
      errorMsg={error}
    />
    </Box>
  );
}
export default EditHoliday;
