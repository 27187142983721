import React from "react";
import { Navigate } from "react-router-dom";
import Login from "./Login";

function PublicRouter() {
  const token = localStorage.getItem("usertoken");
  return token ? <Navigate to="/dashboard" /> : <Login />;          
}

export default PublicRouter;
