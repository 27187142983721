import React, { useEffect, useState } from "react";
import EmployeeForm from "../Common/EmployeeForm";
import moment from "moment";
import Service from "../services";
import { date, number, object, string } from "yup";
import { Card, CardContent, Box } from "@mui/material";
import SnackBar from "../Common/SnackBar";

function UpdateDetails({ userDetails, userID, Edit }) {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const styles = {
    sucesscard: {
      height: "50px",
      backgroundColor: "#28a745",
      color: "#fff",
    },
    successmsg: {
      paddingBottom: "10px",
    },
    errorcard: {
      height: "50px",
      backgroundColor: "#dc3545",
      color: "#fff",
    },
    errormsg: {
      paddingBottom: "10px",
    },
  };
  const isAdd = false;
  const initialValues = {
    first_name:
      Edit === "edit" ? userDetails.first_name : userDetails.firstName,
    middle_name:
      Edit === "edit" ? userDetails.middle_name : userDetails.middleName,
    last_name: Edit === "edit" ? userDetails.last_name : userDetails.lastName,
    gender: `${userDetails.gender}`,
    blood_group:
      Edit === "edit"
        ? `${userDetails.blood_group}`
        : `${userDetails.bloodGroup}`,
    email: userDetails.email,
    mobile_no: Edit === "edit" ? userDetails.mobile_no : userDetails.mobileNo,
    dob:
      Edit === "edit" ? moment(userDetails.dob) : moment(userDetails.birthday),
    employee_id:
      Edit === "edit" ? userDetails.employee_id : userDetails.employeeId,
    joined_on:
      Edit === "edit"
        ? moment(userDetails.joined_on)
        : moment(userDetails.joinedOnDate),
    designation: `${userDetails.designation}`,
  };
  const updateSchema = object({
    first_name: string().required("FirstName is required"),
    email: string().email().required("Email is required"),
    mobile_no: string()
      .required("Mobile number is required")
      .matches(
        /^[0-9]{10}$/,
        "Mobile number must be exactly 10 digits and contain only numbers"
      ),
    dob: date().required("Birthday is required"),
  });
  const getEmployeeDetail = async () => {
    try {
      const id = userID;
      const res = await Service.getEmployeeDetails(id);
    } catch (error) {
    }
  };
  const editDetail = async (value) => {
    try {
      value.id = userID;
      const res = await Service.editEmployeeDetail(value);
      await getEmployeeDetail();
      setSnackOpen(true);
      setSuccess(res.data.message);
    } catch (error) {
      await getEmployeeDetail();
      setSnackOpen(true);
      setError(error.response.data.message);
    }
  };
  return (
    <Box>
      <EmployeeForm
        usDetails={userDetails}
        fetchData={editDetail}
        initialValues={initialValues}
        isAdd={isAdd}
        updateSchema={updateSchema}
      />
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        messageName={success}
        errorMsg={error}
      />
    </Box>
  );
}

export default UpdateDetails;
