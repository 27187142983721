import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Service from "../../services";
import { useParams } from 'react-router-dom';

import { useLocation, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import SnackBar from "../../Common/SnackBar";

const styles = {
  typo1: {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  checkboxlabel: {
    "& .MuiTypography-root": {
      fontWeight: 600,
      color: "rgba(39,44,51,.7)!important",
    },
  },
  label: {
    fontSize: "14px",
    color: "rgba(39,44,51,.7)!important",
  },
  uploadBox: {
    backgroundColor: "#f2f8ff",
    minWidth: 150,
    height: 65,
    borderRadius: 2,
    border: "1px dashed #262d5c",
  },
  boxTypo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cancelButton: {
    backgroundColor: "#f8f9fa",
    color: "#444",
  },
};
function EditLeaveModerator() {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [url, setUrl] = useState("");
  const { state } = useLocation();
  const [snackOpen, setSnackOpen] = useState(false);
  const handleCancel = () => {
    navigate(-1);
  };
  const editId = useParams();
  const initialValues = { 
    remarks: "",
    status: 1,
  };
  const editLeaveModeratorSchema = object({
    remarks: string().required("This field is required"),
    status: string().required("This field is required"),
  });
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: editLeaveModeratorSchema,
    onSubmit: async (values) => {
      values.id = editId.id;
      values.url = url;
      try {
        const res = await Service.ApproveRejectLeave(values);
        navigate("/leaveModerators", {
          state: {
            success:true,
            message: res.data.message,
            open:true,
          },
        });
      } catch (error) {
        setSnackOpen(true)
        setError(error.response.data.message);
        navigate("/leaveModerators", {
          state: {
            success:false,
            open:true,
            message: error.response.data.message,
          },
        });
      }
    },
  });
  useEffect(() => {
    const url = new URL(window.location.href);
    const baseUrl = url.origin;
    if (baseUrl) {
      setUrl(baseUrl);
    } else {
    }
  }, []);
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 1.5, mt: 8, width: "calc(100vw - 260px)" }}
    >
      <Typography style={styles.typo1}>Edit Leave Moderator</Typography>
      <Card
        sx={{
          maxHeight: "auto",
          mt: 1,
          borderRadius: "10px",
        }}
      >
        <CardContent
          sx={{
            color: "rgba(39,44,51,.7)!important",
            fontSize: "14px",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ mt: 2 }}>
              <Typography sx={styles.label}>Remarks:</Typography>
              <TextField
                name="remarks"
                fullWidth
                size="small"
                multiline
                minRows={10}
                value={values.remarks}
                helperText={touched.remarks && errors.remarks}
                error={errors.remarks}
                onChange={handleChange}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={styles.label}>Status</Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                name="status"
                sx={{ width: "100%", maxWidth: "500px" }}
                value={values.status}
                error={errors.status}
                onChange={handleChange}
              >
                <MenuItem value={1}>Pending</MenuItem>
                <MenuItem value={2}>Approve with notify</MenuItem>
                <MenuItem value={3}>Reject with notify</MenuItem>
              </TextField>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Button variant="contained" onClick={handleSubmit}>
                Save
              </Button>
              &nbsp;
              <Button
                variant="contained"
                style={styles.cancelButton}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        errorMsg={error}
      />
    </Box>
  );
}

export default EditLeaveModerator;
