import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  IconButton,
  Pagination,
  Typography,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import Service from "../Components/services";
import AlertDialog from "../Components/Common/AlertDialog";
import { DELETEALBUM, IMAGES_LIST, entries } from "./Constants";
import Footer from "./Footer";
import SnackBar from "./Common/SnackBar";

export default function ImageGallery() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const {state} = useLocation();
  const [open, setOpen] = useState(false);
  const [ids, setIds] = useState();
  const [perPage, setPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const role = localStorage.getItem("role");
  const [snackOpen, setSnackOpen] = useState(state?.open);
  const [msg,setMsg] = useState("")

  const handleChange = (e, p) => {
    setPage(p);
  };
  useEffect(() => {
    listAlbum();
  }, [page, perPage]);
  if (state) {
    setTimeout(() => {
      setSnackOpen(false)
    }, 2000);
  }
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const handleViewAlbum = (id) => {
    navigate(`/album/${id}`, {
      state: {
        albumid: id,
      },
    });
  };
  const handleClick = () => {
    navigate("/album/create");
  };
  const handleEditClick = (id, value) => {
    navigate(`/album/${id}/edit`, {
      state: {
        value: id,
        name: value.name,
        description: value.description,
        image: value.cover_image,
      },
    });
  };
  const handleDeleteClick = (id) => {
    setOpen(true);
    setIds(id);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
     const res = await Service.deleteAlbum(ids);
      listAlbum();
      setSnackOpen(true);
      setMsg(res.data.message)
    } catch (error) {}
    setOpen(false);
  };
  const listAlbum = async () => {
    try {
      const { data } = await Service.listAlbum(page, perPage);
      setData(data.data);
      setPageCount(Math.ceil(data.total_count / perPage));
    } catch (error) {
      // setError(error.response.data);
    }
  };
  const handleFilter = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };
  const styles = {
    addNewButton: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    // delete: {
    //   color: "gray",
    //   ".MuiIconButton-root:hover": {
    //     color: "red",
    //   },
    // },
    typo1: {
      fontSize: "1.8rem",
      fontWeight: 700,
      color: "#000736 !important",
      paddingBottom: "15px",
    },
    cardTypo: {
      color: "#262d5c !important",
      fontWeight: 600,
      fontFamily: "'Inter', sans-serif",
      ml: 2.2,
      mt: 1,
    },
    Button: {
      ml: 1,
      padding: "0.2rem,0.81rem",
      borderRadius: "30px",
      fontSize: "12px",
    },
    paginationbox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "20px",
    },
  };
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
      <Box sx={styles.addNewButton}>
        <Typography style={styles.typo1}>Image Gallery</Typography>
        {role === "admin" || role === "manager" ? (
          <Button
            sx={{ padding: "6px 20px", fontSize: "12px" }}
            variant="contained"
            onClick={handleClick}
          >
            ADD NEW ALBUM
          </Button>
        ) : (
          ""
        )}
      </Box>
      <Box>
        {" "}
        <Typography>
          Show{" "}
          <span>
            <TextField
              id="outlined-select-currency"
              select
              defaultValue="10"
              value={perPage}
              onChange={handleFilter} 
              inputProps={{
                sx: {
                  paddingRight: "30px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                  fontSize: "75%",
                },
              }}
            >
              {entries.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>{" "}
          entries
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {data
          ? data.map((obj) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card sx={{ mt: 2 }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="200"
                      src={IMAGES_LIST + obj.cover_image}
                      alt="album"
                    />
                    <Typography gutterBottom variant="h6" sx={styles.cardTypo}>
                      {obj.name}
                    </Typography>
                  </CardActionArea>
                  <CardActions
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      sx={styles.Button}
                      onClick={() => handleViewAlbum(obj.id)}
                    >
                      View Album
                    </Button>
                    {role === "manager" || role === "admin" ? (
                      <Box>
                        <IconButton
                          sx={{
                            "&:hover": {
                              color: "#1976d2",
                            },
                          }}
                          onClick={() => handleDeleteClick(obj.id)}
                        >
                          <Icon
                            icon="material-symbols:delete-outline"
                            width="25"
                            height="25"
                          />
                        </IconButton>
                        <IconButton
                          sx={{
                            "&:hover": {
                              color: "#1976d2",
                            },
                          }}
                          aria-label="add an alarm"
                          onClick={() => handleEditClick(obj.id, obj)}
                        >
                          <Icon
                            className="edit"
                            icon="bxs:edit"
                            width="25"
                            height="25"
                          />
                        </IconButton>
                      </Box>
                    ) : (
                      ""
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))
          : null}
      </Grid>
      <Box sx={styles.paginationbox}>
        <Pagination
          count={pageCount}
          color="primary"
          shape="rounded"
          page={page}
          onChange={handleChange}
        />
      </Box>
      <AlertDialog
        open={open}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
        title={DELETEALBUM}
      />
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        messageName={msg ? msg : state?.message}
      />
      <Footer />
    </Box>
  );
}
