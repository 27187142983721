import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
const styles = {
  typo2: {
    fontSize: "16px",
    fontWeight: 600,
    color: "rgba(39,44,51,.7)",
    paddingBottom: "10px",
  },
  typoData: {
    fontSize: "16px",
    fontweight: 400,
    color: "#212529",
  },
  typo1: {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  button: {
    backgroundColor: "white",
    color: "#444",
  },
  gridItemButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
};
function ViewLeaveModerators() {
  const navigate = useNavigate();
  const { state } = useLocation();
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
      <Grid container sx={styles.gridContainer}>
        <Grid item md={6}>
          <Typography style={styles.typo1}>{state.header}</Typography>
        </Grid>
        <Grid item md={6} style={styles.gridItemButton}>
          <Button
            style={styles.button}
            variant="contained"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <Card
        sx={{
          maxHeight: "auto",
          mt: 2,
          borderRadius: "10px",
        }}
      >
        <CardContent sx={{ mt: 0.5 }}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {state.datalist.map((item) => (
              <Grid item md={4}>
                <Typography sx={styles.typo2}>{item.head}</Typography>
                <Typography sx={styles.typoData}>{item.value}</Typography>
              </Grid>
            ))}  
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ViewLeaveModerators;
