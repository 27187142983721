import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";
export default function Info({ userDetails, Edit }) {
  const style = {
    cardList: {
      height: "49px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      ":hover": { borderBottom: "0.1px solid rgba(0,0,0,.125)" },
    },
  };
  return (
    <Grid
      container
      spacing={3}
      sx={{
        "& .MuiGrid-item": {
          paddingLeft: "15px",
          paddingTop: "10px",
        },
      }}
    >
      <Grid item md={6}>
        <Card
          sx={{
            maxHeight: "350px",
            maxWidth: "750px",
            mt: 2,
            ml: 2.5,
            borderRadius: "10px",
            border: "0.1px solid #dadce0",
          }}
        >
          <CardContent
            sx={{
              color: "rgba(39,44,51,.7)!important",
              fontSize: "14px",
            }}
          >
            <Box sx={{ height: "40px" }}>
              <Typography
                sx={{ fontSize: "18px", color: "#202124", fontWeight: 500 }}
              >
                Basic info
              </Typography>
            </Box>
            <Divider />
            <Box sx={style.cardList}>
              <Typography>First Name</Typography>
              <Typography sx={{ color: "black", ml: "50%" }}>
                {Edit === "profile"
                  ? userDetails.firstName
                  : userDetails.first_name}
              </Typography>
            </Box>
            <Box sx={style.cardList}>
              <Typography>Middle Name</Typography>
              <Typography sx={{ color: "black", ml: "47.5%" }}>
                {/* {userDetails.middleName} */}
                {Edit === "profile"
                  ? userDetails.middleName
                  : userDetails.middle_name}
              </Typography>
            </Box>
            <Box sx={style.cardList}>
              <Typography>Last Name</Typography>
              <Typography sx={{ color: "black", ml: "50%" }}>
                {Edit === "profile"
                  ? userDetails.lastName
                  : userDetails.last_name}
              </Typography>
            </Box>
            <Box sx={style.cardList}>
              <Typography>Gender</Typography>
              <Typography sx={{ color: "black", ml: "53.5%" }}>
                {userDetails.gender}
              </Typography>
            </Box>
            <Box sx={style.cardList}>
              <Typography>Blood Group</Typography>
              <Typography sx={{ color: "black", ml: "48%" }}>
                {Edit === "profile"
                  ? userDetails.bloodGroup
                  : userDetails.blood_group}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6}>
        <Card
          sx={{
            maxHeight: "auto",
            maxWidth: "750px",
            mt: 2,
            mr: 2,
            borderRadius: "10px",
            border: "0.1px solid #dadce0",
          }}
        >
          <CardContent
            sx={{
              color: "rgba(39,44,51,.7)!important",
              fontSize: "14px",
            }}
          >
            <Box sx={{ height: "40px" }}>
              <Typography
                sx={{ fontSize: "18px", color: "#202124", fontWeight: 500 }}
              >
                Employee info
              </Typography>
            </Box>
            <Divider />
            <Box sx={style.cardList}>
              <Typography>Employee Id</Typography>
              <Typography sx={{ color: "black", ml: "49%" }}>
                {Edit === "profile"
                  ? userDetails.employeeId
                  : userDetails.employee_id}
              </Typography>
            </Box>
            <Box sx={style.cardList}>
              <Typography>Designation</Typography>
              <Typography sx={{ color: "black", ml: "50%" }}>
                {userDetails.designation}
              </Typography>
            </Box>
            <Box sx={style.cardList}>
              <Typography>Email</Typography>
              <Typography sx={{ color: "black", ml: "50%" }}>
                {userDetails.email}
              </Typography>
            </Box>
            <Box sx={style.cardList}>
              <Typography>Mobile No</Typography>
              <Typography sx={{ color: "black", ml: "51.5%" }}>
                {/* {userDetails.mobileNo}  */}
                {Edit === "profile"
                  ? userDetails.mobileNo
                  : userDetails.mobile_no}
              </Typography>
            </Box>
            <Box sx={style.cardList}>
              <Typography>Birthday</Typography>
              <Typography sx={{ color: "black", ml: "53.5%" }}>
                {Edit === "profile"
                  ? userDetails.birthday
                  : moment(userDetails.dob).format("YYYY-MM-DD")}
              </Typography>
            </Box>
            <Box sx={style.cardList}>
              <Typography>Joined On</Typography>

              <Typography sx={{ color: "black", ml: "51.5%" }}>
                {Edit === "profile"
                  ? userDetails.joinedOnDate
                  : moment(userDetails.joined_on).format("YYYY-MM-DD")}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    // </Box>
  );
}
