import React from "react";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { object, string } from "yup";
import Service from ".././services";
import { useState } from "react";
import SnackBar from "../Common/SnackBar";

function ChangePassword({ Edit, userid, initialValues }) {
  const [success, setSuccess] = useState({});
  const [error, setError] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };

  const passwordSchema = object({
    password: string().min(8).required("New Password is required"),
    password_confirmation: string()
      .min(8)
      .required("Confirm Password is required"),
  });
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: passwordSchema,
    onSubmit: async (values, action) => {
      try {
        if (Edit === "profile") {
          const res = await Service.passwordChange(values);
          action.resetForm();
          setSnackOpen(true);
          setSuccess({ value: res.data.message, isSuccess: true });
        } else {
          action.resetForm();
          setSnackOpen(true);
          const res = await Service.changePasswordEmployee(values);
          setSuccess({ value: res.data.message, isSuccess: true });
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.password &&
          error.response.data.errors.password[0]
        ) {
          setSnackOpen(true);
          setSuccess({
            value: error.response.data.errors.password[0].message,
            isSuccess: false,
          });
        } else {
         
        }
        action.resetForm();
      }
    },
  });

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  const styles = {
    sucesscard: {
      height: "50px",
      backgroundColor: "#28a745",
      color: "#fff",
    },
    successmsg: {
      paddingBottom: "10px",
      mt: -1.5,
    },
    errorcard: {
      height: "50px",
      backgroundColor: "#dc3545",
      color: "#fff",
    },
    errormsg: {
      paddingBottom: "10px",
      mt: -1.5,
    },
  };
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          "& .MuiGrid-item": {
            paddingLeft: "15px",
            paddingTop: "10px",
          },
        }}
      >
        <Grid item md={6}>
          <Card
            sx={{
              maxHeight: "350px",
              maxWidth: "750px",
              mt: 2,
              ml: 2.5,
              borderRadius: "10px",
              border: "0.1px solid #dadce0",
            }}
          >
            <CardContent
              sx={{
                color: "rgba(39,44,51,.7)!important",
                fontSize: "14px",
              }}
            >
              <Box sx={{ height: "40px" }}>
                <Typography
                  sx={{ fontSize: "18px", color: "#202124", fontWeight: 500 }}
                >
                  Change Password
                </Typography>
              </Box>
              <Divider />
              <form onSubmit={handleSubmit}>
                <Typography>New Password:</Typography>
                <TextField
                  size="small"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  error={errors.password}
                  onChange={handleChange}
                  helperText={touched.password && errors.password}
                  fullWidth
                />
                <FormControlLabel
                  value="Show Password"
                  control={
                    <Checkbox
                      sx={{ size: "10px" }}
                      checked={showPassword}
                      onChange={handleShowPasswordToggle}
                    />
                  }
                  label={
                    <span style={{ fontSize: "16px", color: "black" }}>
                      Show Password
                    </span>
                  }
                  labelPlacement="end"
                  sx={{ mt: -1 }}
                />
                <Box mt={1}>
                  <Typography>Confirm Password:</Typography>
                  <TextField
                    size="small"
                    type="password"
                    name="password_confirmation"
                    value={values.password_confirmation}
                    error={errors.password_confirmation}
                    onChange={handleChange}
                    helperText={
                      touched.password_confirmation &&
                      errors.password_confirmation
                    }
                    fullWidth
                  />
                </Box>
                <Divider sx={{ mt: 2 }} />
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Button variant="contained" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        messageName={success.isSuccess ? success.value : success.value}
        errorMsg={success.isSuccess ? "": success.value}
      />
      </Grid>
    </>
  );
}

export default ChangePassword;
