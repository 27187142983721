import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import { Avatar, Card, Grid, IconButton } from "@mui/material";
import { CameraAlt } from "@mui/icons-material";
import moment from "moment";
import CancelIcon from "@mui/icons-material/Cancel";
import Footer from "../../Footer";
import ProfileTab from "../../Tab/ProfileTab";
import { IMAGES_LIST } from "../../Constants";
import Service from "../../services";

function ViewEmployee() {
  const [viewUserDetails, setViewUserDetails] = useState([]);
  const [image, setImage] = useState(null);
  const { state } = useLocation();
  const [id, setId] = useState(state?.value);
  const fileInputRef = useRef(null);
  const isView = true;
  const Edit = "edit";
  const initialValues = {
    id: id,
    password: "",
    password_confirmation: "",
  };
  const handleFileChange = (e) => {
    const image = e.target.files[0];
    handleUpload(id, image);
  };
  const handleCancel = () => {
    fileInputRef.current.value = "";
    setViewUserDetails({ ...viewUserDetails, profile_image: null });
  };
  const EmployeeDetails = async () => {
    try {
      const res = await Service.getEmployeeDetails(id);
      setViewUserDetails(res.data);
    } catch (error) {
      // setError(error.response.data.message);
    }
  };

  const handleUpload = async (id, image) => {
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("image", image);
      const res = await Service.editEmployeeImage(formData);
      EmployeeDetails();
      // setViewUserDetails({...viewUserDetails,profile_image: res.data.file });
    } catch (error) {}
  };

  useEffect(() => {
    EmployeeDetails();
  }, []);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize="29px"
          fontWeight={700}
          fontFamily="'Inter', sans-serif"
        >
          Profile
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "16px",
            fontFamily: "'Inter', sans-serif",
          }}
        >
          <Link href="https:/xminds.com" underline="hover">
            Home
          </Link>
          &nbsp;
          <Typography> / User Profile</Typography>
        </Box>
      </Box>
      <Card
        sx={{
          maxWidth: "2000px",
          height: "201px",
          mt: 3,
          borderRadius: "10px",
        }}
      >
        <Grid container sx={{ mt: 3 }}>
          <Grid item md={2}>
            <Grid container>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={IMAGES_LIST + viewUserDetails.profile_image}
                  alt=""
                  sx={{ width: 120, height: 120 }}
                />
              </Grid>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <IconButton component="label" sx={{ color: "#06c4a5" }}>
                  <CancelIcon onClick={handleCancel} sx={{ color: "red" }} />
                </IconButton>

                <IconButton
                  component="label"
                  htmlFor="fileInput"
                  sx={{ color: "#06c4a5" }}
                >
                  <CameraAlt />
                  <input
                    id="fileInput"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={10}>
            <Typography
              sx={{
                fontSize: "18px",
                fontFamily: "'Inter', sans-serif",
                fontWeight: "bold",
              }}
            >
              {`${viewUserDetails.first_name} ${viewUserDetails.middle_name} ${viewUserDetails.last_name}`}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "'Inter', sans-serif",
                fontWeight: "bold",
                color: "#6c757d!important",
              }}
            >
              {viewUserDetails.designation}
            </Typography>
            <Box sx={{ mt: 2, color: "#6c757d!important" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  fontFamily: "'Inter', sans-serif",
                }}
              >
                Joined on:{" "}
                <span>
                  {moment(viewUserDetails.joined_on).format("DD.MM.YYYY")}
                </span>
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  fontFamily: "'Inter', sans-serif",
                }}
              >
                Employee Status:{" "}
                <span>
                  {viewUserDetails.old_employee === true
                    ? "Alumini"
                    : "Current Employee"}
                </span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>

      <Card sx={{ mt: 3, borderRadius: "10px" }}>
        <ProfileTab
          initialValues={initialValues}
          setViewUserDetails={setViewUserDetails}
          Edit={Edit}
          isView={isView}
          fetchData={EmployeeDetails}
          viewUserDetails={viewUserDetails}
          id={id}
        />
      </Card>
      <Footer />
    </Box>
  );
}

export default ViewEmployee;
