import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import Holidays from "./Components/Holidays";
import Login from "./Components/Login";
import PrivateRouter from "./Components/PrivateRouter";
import Profile from "./Components/Profile";
import PublicRouter from "./Components/PublicRouter";
import Layout from "./Components/Layout";
import ApplyForLeave from "./Components/ApplyForLeave";
import AppliedLeaves from "./Components/AppliedLeaves";
import ApplyCompo from "./Components/ApplyCompo";
import AppliedCompo from "./Components/AppliedCompo";
import ImageGallery from "./Components/ImageGallery";
import ViewImageGallery from "./Components/ViewImageGallery";
import LeaveModerators from "./Components/Lead/LeaveModerators";
import CompoModerators from "./Components/Lead/CompoModerators";
import Employees from "./Components/Admin/Manager/Employees";
import Lead from "./Components/Admin/Manager/Lead";
import Manager from "./Components/Admin/Manager/Manager";
import Alumini from "./Components/Admin/Manager/Alumini";
import LeaveTypes from "./Components/Admin/Manager/LeaveTypes";
import Quotes from "./Components/Admin/Manager/Quotes";
import AddEmployee from "./Components/Admin/Manager/AddEmployee";
import AddLeaveType from "./Components/Admin/Manager/AddLeaveType";
import AddQuotes from "./Components/Admin/Manager/AddQuotes";
import AddHolidays from "./Components/Admin/Manager/AddHolidays";
import ViewCard from "./Components/Common/ViewCard";
import ViewQuotes from "./Components/Admin/Manager/ViewQuotes";
import EditQuote from "./Components/Admin/Manager/EditQuote";
import EditHoliday from "./Components/Admin/Manager/EditHoliday";
import EditLeaveType from "./Components/Admin/Manager/EditLeaveType";
import EditEmployee from "./Components/Admin/Manager/EditEmployee";
import ViewEmployee from "./Components/Admin/Manager/ViewEmployee";
import AddNewAlbum from "./Components/Admin/Manager/AddNewAlbum";
import EditAlbum from "./Components/Admin/Manager/EditAlbum";
import UploadImage from "./Components/Admin/Manager/UploadImage";
import ViewLeaveModerators from "./Components/Admin/Manager/ViewLeaveModerators";
import EditLeaveModerators from "./Components/Admin/Manager/EditLeaveModerators";
import ViewCompoModerators from "./Components/Admin/Manager/ViewCompoModerators";
import EditComboModerators from "./Components/Admin/Manager/EditComboModerators";
import Reset from "./Components/Admin/Manager/ResetLeave";
import ForgotPassword from "./Components/Admin/Manager/ForgotPassword";
import ResetPassword from "./Components/Admin/Manager/ResetPassword";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRouter />}>
          <Route element={<Login />} path="login"></Route>
        </Route>
        <Route element={<ForgotPassword />} path="/login/password"></Route>
        <Route element={<ResetPassword />} path="/password/reset/:token"></Route>

        <Route element={<PrivateRouter />}>
          <Route path="/" element={<Layout />}>
            <Route element={<Dashboard />} index></Route>
            <Route element={<Dashboard />} path="/dashboard"></Route>
            <Route element={<Profile />} path="/profile"></Route>
            <Route element={<Holidays />} path="/holidays"></Route>
            <Route element={<ViewCard />} path="/holidays/:id"></Route>
            <Route element={<ApplyForLeave />} path="/applyforleave"></Route>
            <Route element={<AppliedLeaves />} path="/appliedleaves"></Route>
            <Route element={<ApplyCompo />} path="/applycompo"></Route>
            <Route element={<AppliedCompo />} path="/listCompo"></Route>
            <Route element={<ImageGallery />} path="/album"></Route>
            <Route element={<ViewImageGallery />} path="/album/:id"></Route>
            <Route
              element={<LeaveModerators />}
              path="/leaveModerators"
            ></Route>
            <Route
              element={<CompoModerators />}
              path="/compoModerators"
            ></Route>
            <Route element={<Employees />} path="/role/employee/users"></Route>
            <Route element={<Lead />} path="/role/lead/users"></Route>
            <Route element={<Manager />} path="/role/manager/users"></Route>
            <Route element={<Alumini />} path="/role/alumini/users"></Route>
            <Route element={<LeaveTypes />} path="/leaveTypes"></Route>
            <Route element={<Quotes />} path="/quotes"></Route>
            <Route element={<AddEmployee />} path="/users/create"></Route>
            <Route element={<AddLeaveType />} path="/leaveTypes/create"></Route>
            <Route element={<AddQuotes />} path="/quotes/create"></Route>
            <Route element={<AddNewAlbum />} path="/album/create"></Route>
            <Route element={<UploadImage />} path="/album/upload"></Route>
            <Route element={<AddHolidays />} path="/holidays/create"></Route>
            <Route
              element={<ViewLeaveModerators />}
              path="/viewleaveModerators/:id"
            ></Route>
            <Route
              element={<EditLeaveModerators />}
              path="/leaveModerators/:id/edit"
            ></Route>
            <Route
              element={<ViewCompoModerators />}
              path="/viewcompoModerators/:id"
            ></Route>
            <Route
              element={<EditComboModerators />}
              path="/compoModerators/:id/edit"
            ></Route>
            <Route element={<ViewCard />} path="/leaveTypes/:id"></Route>
            <Route element={<ViewQuotes />} path="/quotes/:id"></Route>
            <Route element={<EditQuote />} path="/quotes/:id/edit"></Route>
            <Route element={<EditAlbum />} path="/album/:id/edit"></Route>
            <Route element={<EditHoliday />} path="/holidays/:id/edit"></Route>
            <Route
              element={<EditLeaveType />}
              path="/leaveTypes/:id/edit"
            ></Route>
            <Route element={<Reset />} path="/reset"></Route>
            <Route element={<EditEmployee />} path="/users/:id/edit"></Route>
            <Route element={<ViewEmployee />} path="/users/:id"></Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;
