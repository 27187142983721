import axios from "axios";
import { API_LINK, BACKEND_API_LINK } from "./Constants";

class service {
  async login(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.post(
      `${BACKEND_API_LINK}${API_LINK.login}`,
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  }
  async logout() {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.logout}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  }

  async addEmployee(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.post(
      `${BACKEND_API_LINK}${API_LINK.addemployee}`,
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  }
  async postLeaveType(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.post(
      `${BACKEND_API_LINK}${API_LINK.createleavetype}`,
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  }

  async postQuote(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.post(
      `${BACKEND_API_LINK}${API_LINK.createnewquote}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }

  async postImage(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.post(
      `${BACKEND_API_LINK}${API_LINK.createnewalbum}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async addAlbumImage(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.post(
      `${BACKEND_API_LINK}${API_LINK.addalbumimage}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }

  async postHoliday(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.post(
      `${BACKEND_API_LINK}${API_LINK.createnewholiday}`,
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  }
  async forgotPassword(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.post(
      `${BACKEND_API_LINK}${API_LINK.forgotpassword}`,
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  }
  async ApplyLeave(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.post(
      `${BACKEND_API_LINK}${API_LINK.applyleave}`,
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  }
  async ApproveRejectLeave(data) {
    const token = localStorage.getItem("usertoken");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(
        `${BACKEND_API_LINK}${API_LINK.approverejectleave}?id=${data.id}&remarks=${data.remarks}&status=${data.status}&url=${data.url}`,
        null,
        { headers }
      );

      return response;
    } catch (error) {
      throw error;
    }
  }
  async ApproveRejectCompo(data) {
    const token = localStorage.getItem("usertoken");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(
        `${BACKEND_API_LINK}${API_LINK.approverejectcompo}?id=${data.id}&remarks=${data.remarks}&status=${data.status}&url=${data.url}`,
        null,
        { headers }
      );

      return response;
    } catch (error) {
      throw error;
    }
  }
  async editQuote(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.put(
      `${BACKEND_API_LINK}${API_LINK.editquote}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async resetPassword(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.put(
      `${BACKEND_API_LINK}${API_LINK.updatenewpassword}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async editProfileImage(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.put(
      `${BACKEND_API_LINK}${API_LINK.editprofileimage}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async editEmployeeImage(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.put(
      `${BACKEND_API_LINK}${API_LINK.editemployeeimage}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async editAlbum(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.put(
      `${BACKEND_API_LINK}${API_LINK.editalbum}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }

  async editHoliday(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.put(
      `${BACKEND_API_LINK}${API_LINK.editholiday}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async changePasswordEmployee(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.put(
      `${BACKEND_API_LINK}${API_LINK.changepasswordemployee}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }

  async passwordChange(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.put(
      `${BACKEND_API_LINK}${API_LINK.passwordchange}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async editLeaveType(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.put(
      `${BACKEND_API_LINK}${API_LINK.editleavetype}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }

  async deleteQuote(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.delete(
      `${BACKEND_API_LINK}${API_LINK.deletequote}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: data,
        },
      }
    );
    return response;
  }

  async deleteLeaveModerators(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.delete(
      `${BACKEND_API_LINK}${API_LINK.deleteleavemoderator}?id=${data}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async deleteCompoModerators(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.delete(
      `${BACKEND_API_LINK}${API_LINK.deletecombomoderator}?id=${data}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async deleteAlbum(data) {
    const token = localStorage.getItem("usertoken");
    const formData = new FormData();
    formData.append("id", data);
    const response = await axios.delete(
      `${BACKEND_API_LINK}${API_LINK.deletealbum}`,
      {
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async deleteAlbumImage(data) {
    const token = localStorage.getItem("usertoken");
    const formData = new FormData();
    formData.append("album-id", data.albumid);
    formData.append("image-id",data.imageid)
    const response = await axios.delete(
      `${BACKEND_API_LINK}${API_LINK.deletealbumimage}`,
      {
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async deleteRole(data) {
    const token = localStorage.getItem("usertoken");
    const formData = new FormData();
    formData.append("roleid", data.roleid);
    formData.append("modelid", data.modelid);
    const response = await axios.delete(
      `${BACKEND_API_LINK}${API_LINK.deleterole}`,
      {
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }

  async deleteHoliday(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.delete(
      `${BACKEND_API_LINK}${API_LINK.deleteholiday}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: data,
        },
      }
    );
    return response;
  }
  async deleteReportingUser(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.delete(
      `${BACKEND_API_LINK}${API_LINK.deletereportinguser}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: data,
        },
      }
    );
    return response;
  }

  async deleteLeaveTypes(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.delete(
      `${BACKEND_API_LINK}${API_LINK.deleteleavetype}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: data,
        },
      }
    );
    return response;
  }

  async listQuotes(page,perPage,query,order,field) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.listallquote}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          page: page,
          limit: perPage,
          search: query,
          order: order,
          field: field,
        },
      }
    );
    return response;
  }
  async listAllAlumini(page,perPage,query,order,field) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.listallalumni}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          page: page,
          limit: perPage,
          search: query,
          order: order,
          field: field,
          // year: new Date().getFullYear(),
        },
      }
    );
    return response;
  }
  async getUserdetails() {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(`${BACKEND_API_LINK}${API_LINK.me}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  }
  async listHoliday(value1, value2, value3, value4, value5) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.listholiday}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          page: value1,
          limit: value2,
          search: value3,
          order: value4,
          field: value5,
          year: new Date().getFullYear(),
        },
      }
    );
    return response;
  }

  async LeaveModerationLeadList(value1, value2, value3, value4) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.leavemoderatorationleadlist}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          search: value1,
          date: value2,
          field: value3,
          order: value4,
        },
      }
    );
    return response;
  }
  async CompoModerationLeadList(search, date, field, order) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.compomoderatorationleadlist}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          search: search,
          date: date,
          field: field,
          order: order,
        },
      }
    );
    return response;
  }
  async listAlbum(value1, value2) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.getalbumlistdata}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          page: value1,
          limit: value2,
        },
      }
    );
    return response;
  }
  async albumImageList(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.getalbumimagelist}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          album: data.album,
          page: data.page,
          limit: data.limit,
        },
      }
    );
    return response;
  }

  async deleteEmployee(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.delete(
      `${BACKEND_API_LINK}${API_LINK.deleteemployee}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: data,
        },
      }
    );
    return response;
  }
  async removeLeaveType(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.delete(
      `${BACKEND_API_LINK}${API_LINK.removeleavetype}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          userid: data.userid,
          type: data.type,
        },
      }
    );
    return response;
  }
  async listEmployee(page,perPage,query,order,field) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.listallemployees}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: page,
          limit: perPage,
          search: query,
          order: order,
          field: field,
        },
   
      }
    );
    return response;
  }

  async getAllReportingUsers(value) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.getallreportinguserslist}?search=${value}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async getQuote() {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.getallquotelist}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async addReportingUser(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.post(
      `${BACKEND_API_LINK}${API_LINK.addReportingUser}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async addLeaveType(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.post(
      `${BACKEND_API_LINK}${API_LINK.addleavetype}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async applyCompo(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.post(
      `${BACKEND_API_LINK}${API_LINK.applycompo}`,data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async resetLeave() {
    const token = localStorage.getItem("usertoken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        `${BACKEND_API_LINK}${API_LINK.resetleave}`,
        {},
        config
      );
      return response;
    } catch (error) {
      // Handle errors here
      throw error;
    }
  }
  async UploadImage(data) {
    const formData = new FormData();
    formData.append("image", data);
    const token = localStorage.getItem("usertoken");
    const response = await axios.post(
      `${BACKEND_API_LINK}${API_LINK.useruploadfile}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async addRole(data) {
    const token = localStorage.getItem("usertoken");
    const formData = new FormData();
    formData.append("roleid", data.roleid);
    formData.append("modelid", data.modelid);

    const response = await axios.post(
      `${BACKEND_API_LINK}${API_LINK.addrole}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async listLeave(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.listleavedatauser}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          id: data,
        },
      }
    );
    return response;
  }
  async getListTypeEmployee(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.listleavetypeemployee}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          id: data,
        },
      }
    );
    return response;
  }
  async listCompo(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.listcompodatauser}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          id: data,
        },
      }
    );
    return response;
  }
  async listLeaveEmployee(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.listleaveemployee}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          userid: data,
        },
      }
    );
    return response;
  }
  async listLead(page,perPage,query,order,field) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.listallleads}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: page,
          limit: perPage,
          search: query,
          order: order,
          field: field,
          // year: new Date().getFullYear(),
        },
      }
    );
    return response;
  }
  async listManager(page,perPage,query,order,field) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.listallmanagers}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: page,
          limit: perPage,
          search: query,
          order: order,
          field: field,
          // year: new Date().getFullYear(),
        },
      }
    );
    return response;
  }
  async listAlumini(value1) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.listallalumni}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // params: {
        //   page: value1,
        // },
      }
    );
    return response;
  }
  async listLeaveTypes(page,perPage,query,order,field) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.listleavetypes}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          page: page,
          limit: perPage,
          search: query,
          order: order,
          field: field,
        },
      }
    );
    return response;
  }
  async listLeaveTypesData(page,perPage,query,order,field) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.listleavetypesdata}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          page: page,
          limit: perPage,
          search: query,
          order: order,
          field: field,
        },
      }
    );
    return response;
  }

  async getEmployeeDetails(value) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.getemployeedetail}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: value,
        },
      }
    );
    return response;
  }
  async comboModerationDetails(value) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.comboModerationDetails}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: value,
        },
      }
    );
    return response;
  }
  async leaveModerationDetails(value) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.leaveModerationDetails}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: value,
        },
      }
    );
    return response;
  }
  async editEmployee(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.put(
      `${BACKEND_API_LINK}${API_LINK.editemployee}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async editEmployeeDetail(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.put(
      `${BACKEND_API_LINK}${API_LINK.editemployeedetail}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async getUserRole(num) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.getuserrole}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: num,
        },
      }
    );
    return response;
  }
  async getReportingUserList() {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.getreportinguserslist}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async getAppliedCompoList(page, query, order, perPage,field) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.appliedcompolist}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          page: page,
          limit: perPage,
          search: query,
          status: 0,
          order: order,
          field: field,
          year: new Date().getFullYear(),
        },
      });
      return response;
    }
    async getAppliedLeaveList(page, query, order, perPage,field) {
      const token = localStorage.getItem("usertoken");
      const response = await axios.get(
        `${BACKEND_API_LINK}${API_LINK.appliedleavelist}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            page: page,
            limit: perPage,
            search: query,
            status: 0,
            order: order,
            field: field,
            year: new Date().getFullYear(),
          },
        });
        return response;
      }
  async getAllRole() {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.getallrole}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async getRoleAssign(data) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.getroleassign}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: data,
        },
      }
    );
    return response;
  }

  async getRole() {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.getrole}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async getReportingUsers(num) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.getreportingusers}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: num,
        },
      }
    );
    return response;
  }
  async getUserAvailableLeaveType(value) {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.useravailableleaves}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: value,
        },
      }
    );
    return response;
  }
  async getAllLeaveTypes() {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(
      `${BACKEND_API_LINK}${API_LINK.listallleavetypes}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  async updateLeaveCount(data) {
    const token = localStorage.getItem("usertoken");
    try {
      const response = await axios.put(
        `${BACKEND_API_LINK}${API_LINK.updateleavecount}?userid=${data.userid}&type=${data.type}&leavecount=${data.leavecount}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}
export default new service();
