import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Service from "../../services";
import SnackBar from "../../Common/SnackBar";

const styles = {
  typo1: {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  checkboxlabel: {
    "& .MuiTypography-root": {
      fontWeight: 600,
      color: "rgba(39,44,51,.7)!important",
    },
  },
  label: {
    fontSize: "14px",
    color: "rgba(39,44,51,.7)!important",
  },
  uploadBox: {
    backgroundColor: "#f2f8ff",
    minWidth: 150,
    height: 65,
    borderRadius: 2,
    border: "1px dashed #262d5c",
  },
  boxTypo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sucesscard: {
    height: "50px",
    backgroundColor: "#28a745",
    color: "#fff",
  },
  successmsg: {
    paddingBottom: "10px",
  },
};
function ResetLeave() {
  const [data, setData] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const handleReset = async () => {
    setLoading(true);
    try {
      const res = await Service.resetLeave();
      setLoading(false);
      setSnackOpen(true);
      setData(res.data.Message);
    } catch (error) {}
  };
  const moment = require("moment");

  const currentDate = moment();

  const startDate = moment([currentDate.year(), 11, 31]);
  const endDate = moment([currentDate.year(), 0, 15]);

  const isActive = currentDate >= startDate && currentDate <= endDate;

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 1.5, mt: 8, width: "calc(100vw - 260px)" }}
    >
      <Typography style={styles.typo1}>Reset Leaves</Typography>
      <Card
        sx={{
          maxHeight: "auto",
          textAlign: "center",
          mt: 1,
          borderRadius: "10px",
        }}
      >
        <CardContent
          sx={{
            color: "rgba(39,44,51,.7)!important",
            fontSize: "14px",
          }}
        >
          {!isActive ? (
            <Typography>The button is inactive at the moment</Typography>
          ) : (
            ""
          )}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <LoadingButton
              size="small"
              variant="contained"
              sx={{backgroundColor : "#000737"}}
              // disabled={!isActive}
              disabled={loading}
              loading={loading}
              loadingIndicator="Reseting…"
              onClick={handleReset}
              endIcon={<RestartAltIcon />}
            >
              <span>Reset</span>
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        messageName={data}
      />
    </Box>
  );
}

export default ResetLeave;
