import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { date, number, object, string } from "yup";
import EmployeeForm from "../../Common/EmployeeForm";
import Footer from "../../Footer";
import Service from "../../services";
import SnackBar from "../../Common/SnackBar";


function AddEmployee() {
  const [error, setError] = useState("")
  const [snackOpen, setSnackOpen] = useState(false);
  const navigate = useNavigate();
  const isAdd = true;
  const initialValues = {
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    blood_group: "",
    password: "",
    employee_id: "",
    designation: "",
    email: "",
    mobile_no: "",
    dob: null,
    joined_on: null
  };
  const updateSchema = object({
    first_name: string().matches(/^[a-zA-Z\s]*$/,"Name must contain only letters").required("Firstname is required"),
    middle_name: string().matches(/^[a-zA-Z\s]*$/,"Name must contain only letters").required("Middlename is required"),
    last_name: string().matches(/^[a-zA-Z\s]*$/,"Name must contain only letters").required("Lastname is required"),
    gender: string().required("Gender is required"),
    blood_group: string().required("Blood Group is required"),
    password: string().min(8,"Minimum 8 characters").required("Password is required"),
    employee_id: string().required("Employee Id is required"),
    email: string().email().required("Email is required"),
    designation: string().required("Designation is required"),
    mobile_no: string().required('Mobile number is required')
    .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits and contain only numbers'),
    dob: date().required('Birthday is required'),
    joined_on: date().required('Joining Date is required'),
  });
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };  
  const styles = {
    typo1: {
      fontSize: "1.8rem",
      fontWeight: 700,
    },
    sucesscard: {
      height: "50px",
      backgroundColor: "#28a745",
      color: "#fff",
    },
    errorcard: {
      height: "50px",
      backgroundColor: "#dc3545",
      color: "#fff",
    },
    successmsg: {
      paddingBottom: "10px",
    },
  };
  const addEmployee = async (value) => {
    try {
      const res = await Service.addEmployee(value);
      navigate("/role/employee/users", {
        state: {
          message: res.data.message,
          open:true,
        },
      });
    } catch (error) {
      setSnackOpen(true);
      setError(error.response.data.message);
    }
  };
  
  return (
    <>
    <Box sx={styles.successmsg}>
        {error ? (
          <Card sx={styles.errorcard}>
            <CardContent>{error}</CardContent>
          </Card>
        ) : null}
      </Box>
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 1.5, mt: 8, width: "calc(100vw - 260px)" }}
    >
      <Typography style={styles.typo1}>Create User</Typography>
      <Card
        sx={{
          maxHeight: "auto",
          mt: 1,
          borderRadius: "10px",
        }}
      >
        <CardContent
          sx={{
            color: "rgba(39,44,51,.7)!important",
            fontSize: "14px",
          }}
        >
          <EmployeeForm error={error} initialValues={initialValues} isAdd={isAdd} updateSchema={updateSchema} fetchData={addEmployee} />
        </CardContent>
      </Card>
      <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        errorMsg={error}
      />
      <Footer />
    </Box>
    </>
  );
}

export default AddEmployee;
