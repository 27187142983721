import { Box, Card, CardContent, Typography } from "@mui/material";

import React, { useState } from "react";
import { date, object, string } from "yup";
import Service from "../../services";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";
import HolidayAddEdit from "../../Common/HolidayAddEdit";
import SnackBar from "../../Common/SnackBar";

const styles = {
  typo1: {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  errorcard: {
    height: "50px",
    backgroundColor: "#dc3545",
    color: "#fff",
  },
  errormsg: {
    paddingBottom: "10px",
  },
};
function AddHolidays() {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [snackOpen, setSnackOpen] = useState(false);
  const initialValues = {
    date: null,
    description: "",
    status: 0,
    title: "",
  };
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  }; 
  const addHolidaySchema = object({
    title: string().required("The title field is required").matches(/^[a-zA-Z\s]*$/,"Title must contain only letters"),
    date: date().required("The date field is required"),
  });
  const addHoliday = async (value) => {
    try {
      const res = await Service.postHoliday(value);
      navigate("/holidays", {
        state: {
          message: res.data.message,
          open:true,
        },
      });
    } catch (error) {
      setSnackOpen(true);
      setError(error.response.data.error);
    }
  };
  

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 2, mt: 8, width: "calc(100vw - 260px)" }}
    >
      <Typography style={styles.typo1}>Create Holiday</Typography>
      <HolidayAddEdit
        initialValues={initialValues}
        holidaySchema={addHolidaySchema}
        fetchData={addHoliday}
      />
      <Footer />
      <SnackBar
      openSnackerBar={snackOpen}
      handleCloseSnackBar={handleCloseSnackbar}
      errorMsg={error}
    />
    </Box>
  );
}

export default AddHolidays;
