import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { IMAGES_LIST, QUOTE, QUOTEHEAD } from "../../Constants";
const styles = {
  typo2: {
    fontSize: "16px",
    fontWeight: 600,
    color: "rgba(39,44,51,.7)",
    paddingBottom: "10px",
  },
  typoData: {
    fontSize: "16px",
    fontweight: 400,
    color: "#212529",
    ml:1,
  },
  typo1: {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  button: {
    backgroundColor: "white",
    color: "#444",
  },
  gridItemButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
};

function ViewQuotes() {
  const navigate = useNavigate();
  const { state } = useLocation();
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
      <Grid container sx={styles.gridContainer}>
        <Grid item md={6}>
          <Typography style={styles.typo1}>{QUOTE}</Typography>
        </Grid>
        <Grid item md={6} style={styles.gridItemButton}>
          <Button
            style={styles.button}
            variant="contained"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <Card
        sx={{
          maxHeight: "auto",
          mt: 2,
          borderRadius: "10px",
        }}
      >
        <CardContent sx={{ mt: 0.5 }}>
          <Grid container rowSpacing={3}>
            <Grid item md={12}>
              <Typography sx={styles.typo2}>{QUOTEHEAD.des}:</Typography>
              <Typography sx={styles.typoData}>{state.description}</Typography>
            </Grid>
            {state.image && (
              <Grid item md={12}>
                <Typography sx={styles.typo2}>{QUOTEHEAD.image}:</Typography>
                <Avatar
                  alt="Quotes Image"
                  src={IMAGES_LIST + state.image}
                  sx={{ width: 100, height: 100 }}
                />
              </Grid>
            )}
            <Grid item md={12} sx={{display:"flex"}}>
              <Typography sx={styles.typo2}>{QUOTEHEAD.status}: {" "}</Typography>
              <Typography sx={styles.typoData}>
               {state.status === 1 ? "Active" : "Inactive"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ViewQuotes;
