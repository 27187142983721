import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
const styles = {
  boxCheck: {
    mt: 1,
  },
  cancelButton: {
    backgroundColor: "#f8f9fa",
    color: "#444",
  },
  label: {
    fontSize: "14px",
    color: "rgba(39,44,51,.7)!important",
  },
  checkboxlabel: {
    "& .MuiTypography-root": {
      fontWeight: 600,
      color: "rgba(39,44,51,.7)!important",
    },
  },
};
function HolidayAddEdit({ initialValues, holidaySchema,fetchData }) {
  const navigate = useNavigate();
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: holidaySchema,
      onSubmit: (values) => {
        fetchData(values)
      },
    });
  const handleCancel = () => {
    navigate("/holidays", { replace: true });
  };
  return (
    <Card
      sx={{
        maxHeight: "1000px",
        mt: 2,
        borderRadius: "10px",
      }}
    >
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography sx={styles.label}>Title:</Typography>
              <TextField
                name="title"
                fullWidth
                size="small"
                value={values.title}
                error={errors.title}
                onChange={handleChange}
                helperText={touched.title && errors.title}
              />
            </Grid>
            <Grid item md={6}>
              <Typography sx={styles.label}>Date:</Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  name="date"
                  format="YYYY-MM-DD"
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: "100%",
                      error: errors.date,
                      helperText: touched.date && errors.date,
                    },
                  }}
                  value={moment(values.date)}
                  onChange={(value) => {
                    setFieldValue(
                      "date",
                      value.format("YYYY-MM-DD"),
                      true
                    );
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={12}>
              <Typography sx={styles.label}>Description:</Typography>
              <TextField
                name="description"
                fullWidth
                size="small"
                multiline
                minRows={10}
                value={values.description}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Box sx={styles.boxCheck}>
            <FormControlLabel
              name="status"
              control={
                <Checkbox
                  sx={{ transform: "scale(0.8)" }}
                  checked={values.status}
                  onChange={(event) => {
                    const isChecked = event.target.checked;
                    setFieldValue("status", isChecked === true ? 1 : 0,true);
                  }}
                />
              }
              label={<span sx={{ fontSize: "14px" }}>Status</span>}
              sx={styles.checkboxlabel}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
            &nbsp;
            <Button
              variant="contained"
              style={styles.cancelButton}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}

export default HolidayAddEdit;
