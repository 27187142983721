import React, { useEffect, useState } from "react";
import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Service from ".././services";

import {listCompoTableHeader } from "../Constants";
import moment from "moment";

function Compo({ ids, Edit }) {
  const [data, setData] = useState([]);
  const listCompo = async () => {
    try {
      const res = await Service.listCompo(ids);
      setData(res.data);
    } catch (error) {
      // Handle errors here
    }
  };
  
  useEffect(() => {
    listCompo();
  }, [ids]);


  const styles = {
    tableBox: {
      mt: 3,
    },
    ".MuiTableCell-root ": {
      color: "#495057",
      fontFamily: "Inter, sans-serif",
    },
  };
  return (
    <Grid
      container
      sx={{
        "& .MuiGrid-item": {
          paddingLeft: "15px",
          paddingTop: "10px",
        },
      }}
    >
      <Grid item md={12}>
        <Card
          sx={{
            maxHeight: "auto",
            mt: -1,
            ml: -2,
            borderRadius: "10px",
            border: "0.1px solid #dadce0",
          }}
        >
          <CardContent
            sx={{
              color: "rgba(39,44,51,.7)!important",
              fontSize: "14px",
            }}
          >
            <Box sx={{ height: "40px" }}>
              <Typography
                sx={{ fontSize: "18px", color: "#202124", fontWeight: 500 }}
              >
                Compo
              </Typography>
            </Box>
            <Divider />
            <Box sx={styles.tableBox}>
              <TableContainer component={Box} sx={{ borderRadius: "10px" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ background: "#c2d0ff" }}>
                    <TableRow>
                      {listCompoTableHeader.map((value) => (
                        <TableCell align="left" key={value.id}>
                          {value.label.toUpperCase()}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.map((value) => (
                        <TableRow
                          key={value.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            style={styles[".MuiTableCell-root "]}
                            align="left"
                          >
                            {moment(value.compo_date).format("MMM DD, YYYY")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={styles[".MuiTableCell-root "]}
                          >
                            {value.compo_project}{" "}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={styles[".MuiTableCell-root "]}
                          >
                            {moment(value.applied_date).format("MMM DD, YYYY")}{" "}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={styles[".MuiTableCell-root "]}
                          >
                            {value.status}{" "}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Compo;
