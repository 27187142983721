import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AlertDialog from "../Common/AlertDialog";
import Service from "../services";

import {
  entries,
  listLeaveModeratorsTableHeader,
  DELETELEAVEMODERATORS,
} from "../Constants";
import Footer from "../Footer";
import { LEAVEMODERATORSHEAD, LEAVEMODERATORS } from "../Constants";
import SnackBar from "../Common/SnackBar";

function LeaveModerators() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [msg,setMsg] = useState("")
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [query, setQuery] = useState("");
  const [order, setOrder] = useState("asc");
  const [date, setDate] = useState("");
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [field, setField] = useState("type");
  const [perPage, setPerPage] = useState(10);
  const [snackOpen, setSnackOpen] = useState(state?.open);
  window.history.replaceState({}, document.title);
  const style = {
    sucesscard: {
      height: "50px",
      backgroundColor: "#28a745",
      color: "#fff",
    },
    successmsg: {
      paddingBottom: "10px",
      mt: 2,
    },
    errorcard: {
      height: "50px",
      backgroundColor: "#dc3545",
      color: "#fff",
    },
    errormsg: {
      paddingBottom: "10px",
      mt: -1.5,
    },
  };
  const handleChange = (e, p) => {
    setPage(p);
  };
  useEffect(() => {
    LeaveModeratorsList();
  }, [query, date, field, order, perPage]);

  const LeaveModeratorsList = async () => {
    try {
      const dates = date === "Invalid date" ? "" : date
      const res = await Service.LeaveModerationLeadList(
        query,
        dates,
        field,
        order
      );
      setData(res.data.data);
      setPageCount(Math.ceil(res.data.count / perPage));
    } catch (error) {}
  };
  const handleView = (val) => {
    const LeaveModeratorHeader = [
      { head: LEAVEMODERATORSHEAD.Leave_Type, value: val.leave_type },
      {
        head: LEAVEMODERATORSHEAD.Start_Date,
        value: val.from,
      },
      { head: LEAVEMODERATORSHEAD.User_Email, value: val.moderator },
      {
        head: LEAVEMODERATORSHEAD.Number_Of_Leaves,
        value: val.number_of_leaves,
      },

      {
        head: LEAVEMODERATORSHEAD.End_Date,
        value: val.to,
      },

      {
        head: LEAVEMODERATORSHEAD.Back_to_work_Date,
        value: val.back_to_work_date,
      },

      {
        head: LEAVEMODERATORSHEAD.Compo_Leave,
        value: val.status,
      },
      {
        head: LEAVEMODERATORSHEAD.User_Name,
        value: val.name,
      },
      {
        head: LEAVEMODERATORSHEAD.Remarks,
        value: val.remarks,
      },
    ];
    navigate(`/viewleavemoderators/${val.id}`, {
      state: {
        datalist: LeaveModeratorHeader,
        header: LEAVEMODERATORS,
      },
    });
  };
  const handleEdit = (val) => {
    navigate(`/leaveModerators/${val.id}/edit`, {
      state: {
        value: val,
      },
    });
  };
  const handleDeleteClick = (id) => {
    setId(id);
    setOpen(true);
  };
  if (state) {
    setTimeout(() => {
      setSnackOpen(false)
    }, 2000);
  }
  const handleCloseSnackbar = (event, reason) => {
    setSnackOpen(false);
    if (reason === "timeout") {
      setSnackOpen(false);
    }
  };
  const handleDelete = async () => {
    try {
      const res = await Service.deleteLeaveModerators(id);
      setMsg(res.data.error);
      setSnackOpen(true)
      await LeaveModeratorsList();
      setOpen(false);
    } catch (error) {
      // Handle errors here
      // setError(error.response.data.message);
    }
    setOpen(false);
  };
  
  const handleSort = (column) => {
    const isAsc = field === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setField(column);
  };

  const handleFilter = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };
  const handleSearch = (e) => {
    setQuery(e.target.value);
    setPage(1);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleDateChange = (e) => {
    setDate(moment(e).format("YYYY-MM-DD"));
    if(e === null) {
      LeaveModeratorsList();
    }else{

    }
  };
  const styles = {
    typo1: {
      fontSize: "1.8rem",
      fontWeight: 700,
    },
    action1: {
      fontSize: "10px",
      p: "6px 16px 5px",
      borderColor: "#5b67c7",
      color: "#5b67c7",
      ":hover": {
        color: "#fff !important",
        backgroundColor: "#5b67c7",
        borderColor: "#5b67c7",
      },
    },
    action2: {
      fontSize: "10px",
      p: "6px 16px 5px",
      borderColor: "#38cab3",
      color: "#38cab3",
      ":hover": {
        color: "#fff !important",
        backgroundColor: "#38cab3",
        borderColor: "#38cab3",
      },
    },
    action3: {
      fontSize: "10px",
      p: "6px 16px 5px",
      borderColor: "#dc3545",
      color: "#dc3545",
      ":hover": {
        color: "#fff !important",
        backgroundColor: "#dc3545",
        borderColor: "#dc3545",
      },
    },
    sucesscard: {
      height: "50px",
      backgroundColor: "#28a745",
      color: "#fff",
    },
    successmsg: {
      paddingBottom: "10px",
    },
    ".MuiTableCell-root ": {
      color: "#495057",
      fontFamily: "Inter, sans-serif",
    },
  };
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 2, mt: 8, width: "calc(100vw - 260px)" }}
    >
      <Typography style={styles.typo1}>Leave Moderators</Typography>
      <Box>
        <Card
          sx={{
            maxHeight: "auto",
            mt: 1,
            // ml: -2,
            borderRadius: "10px",
            border: "0.1px solid #dadce0",
          }}
        >
          <CardContent
            sx={{
              color: "rgba(39,44,51,.7)!important",
              fontSize: "14px",
            }}
          >
            <Grid
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid item xs={4} md={4}>
                <Typography>
                  Show{" "}
                  <span>
                    <TextField
                      id="outlined-select-currency"
                      select
                      defaultValue="10"
                      value={perPage}
                      onChange={handleFilter} // (e) => setPerPage(e.target.value)
                      inputProps={{
                        sx: {
                          paddingRight: "30px",
                          paddingTop: "3px",
                          paddingBottom: "3px",
                          fontSize: "75%",
                        },
                      }}
                    >
                      {entries.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </span>{" "}
                  entries
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDiretion: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography>Search:</Typography>&nbsp;
                  <TextField
                    size="small"
                    onChange={handleSearch}
                    inputProps={{
                      sx: {
                        height: "1em",
                      },
                    }}
                  ></TextField>
                </Box>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      views={['year', 'month', 'day']}
                      name="date"
                      format="YYYY-MM-DD"
                      inputProps={{
                        sx: {
                          width: "100%",
                          maxWidth: "200px",
                        },
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },

                        field: { clearable: true },
                      }}
                      onChange={handleDateChange}
                    />
                  </LocalizationProvider>
                  
                </Box>
              </Grid>
            </Grid>

            <TableContainer
              component={Box}
              sx={{ mt: 1.5, borderRadius: "10px" }}
            >
              <Table sx={{ minWidth: 700 }} aria-label="simple table">
                <TableHead sx={{ background: "#c2d0ff" }}>
                  <TableRow>
                    {listLeaveModeratorsTableHeader.map((value) => (
                      <TableCell align="left" key={value.id}>
                        <TableSortLabel
                          active={field === value.label}
                          direction={field === value.label ? order : "asc"}
                          onClick={() => handleSort(value.label)}
                        >
                          {value.label.toUpperCase()}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data ? (
                    data.map((value) => (
                      <TableRow
                        key={value.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={styles[".MuiTableCell-root "]}
                          align="left"
                        >
                          {/* {moment(value.compo_date).format("MMM DD, YYYY")} */}
                          {value.leave_type}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={styles[".MuiTableCell-root "]}
                        >
                          {value.moderator}{" "}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={styles[".MuiTableCell-root "]}
                        >
                          {value.reporting_user}{" "}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={styles[".MuiTableCell-root "]}
                        >
                          {value.from}{" "}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={styles[".MuiTableCell-root "]}
                        >
                          {value.to}{" "}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={styles[".MuiTableCell-root "]}
                        >
                          {value.number_of_leaves}{" "}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={styles[".MuiTableCell-root "]}
                        >
                          {value.status}{" "}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={styles[".MuiTableCell-root "]}
                        >
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Button
                              variant="outlined"
                              sx={styles.action1}
                              onClick={() => handleView(value)}
                            >
                              View
                            </Button>
                            &nbsp;
                            {value.status === "Pending" ? (
                              <Button
                                variant="outlined"
                                sx={styles.action2}
                                onClick={() => handleEdit(value)}
                              >
                                Edit
                              </Button>
                             ) : (
                               ""
                             )}
                            &nbsp;
                            <Button
                              variant="outlined"
                              sx={styles.action3}
                              onClick={() => handleDeleteClick(value.id)}
                            >
                              Delete
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={12}
                        style={styles.tableCellNoData}
                      >
                        No Matching Records Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center-",
              }}
            >
              <Pagination
                count={pageCount}
                color="primary"
                shape="rounded"
                page={page}
                onChange={handleChange}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <AlertDialog
        open={open}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
        title={DELETELEAVEMODERATORS}
      />
           <SnackBar
        openSnackerBar={snackOpen}
        handleCloseSnackBar={handleCloseSnackbar}
        messageName={msg ? msg : state?.message}
        errorMsg={state?.success ? "" : state?.message}
      />
      <Footer />
    </Box>
  );
}

export default LeaveModerators;
